import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import Vuex from 'vuex'
import store from './store'
import router from './router'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import Print from 'vue-print-nb'
import axios from 'axios'
import Mixin from './mixin'
import VueAxios from 'vue-axios'
import XEUtils from "xe-utils";
import Lodop from './utils/LodopFuncs'
//import { checkOrTryHttp } from './utils/LodopFuncs'
//checkOrTryHttp() // 现在可以正常调用
//import NProgress from 'nprogress'
//import 'nprogress/nprogress.css'
Vue.use(Lodop)
Vue.mixin(Mixin)
VXETable.use(VXETablePluginElement)
VXETable.use(VXETablePluginExportXLSX)
Vue.use(Vuex)
Vue.use(VXETable)
Vue.use(Print); //注册
Vue.use(ElementUI, {locale})
Vue.config.productionTip = true
Vue.config.devtools = true
Vue.use(ElementUI);
Vue.prototype.$XModal = VXETable.modal
//Vue.prototype.$XPrint = VXETable.print
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.$http = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.$XEUtils = XEUtils    //全局注册，使用方法为:this.$XEUtils
Vue.use(VueAxios, axios)

/*
axios.interceptors.request.use(config => {
    //config.params
   /!* if (window.sessionStorage.getItem("roleVersion") != null){
        config.params = {roleVersion:window.sessionStorage.getItem("roleVersion")}
    }*!/
    return config
})


 */

//在request拦截器中显示进度条Nprogress.start()
axios.interceptors.request.use(config => {
    //请求开始时显示进度条
    //NProgress.start();
    return config
})

//返回状态判断
axios.interceptors.response.use(
    response => {
        //response中完成进度条Nprogress.done()
        //NProgress.done();
        //console.log(response);
        if(response.status === 200 && response.data.state == 'fail' && response.data.closeSystem){
            window.location = 'http://www.baidu.com';
        }else if(response.status === 200 && response.data.state == 'fail'  && response.data.showCommonStr){
            ElementUI.Message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
            });
        }else{
            return response
        }
        //closeSystem
    }
)

axios.defaults.withCredentials = true; // 允许携带cookie

//千分位
Vue.prototype.formatMoney = function (num) {
    return XEUtils.commafy(num, {digits: 2 })
    //return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
}
//千分位 凭证
Vue.prototype.formatMoneyVoucher = function (s) {
    let num = Number(s).toFixed(2) + "";
    if(!this.isBlankVue(s) && num.indexOf(".") == -1 ){
        num = num + '00';//如果没.说明是正数，自动多加00
    }
    if(!this.isBlankVue(s) && num.endsWith(".0")){
        num = num + '0';//如果没.说明是正数，自动多加00
    }
    if(!this.isBlankVue(s) && num.indexOf(".") > 0){
        //num = num.replaceAll(".", "");//有.则去掉
        //num = num.replace(/./g,  "");//有.则去掉
        num = num.replace(".",  "");//有.则去掉
    }
    if(!this.isBlankVue(s) && num.indexOf(",") > 0){
        //num = num.replaceAll(",", "");//有.则去掉
        //num = num.replace(/,/g, "");//有.则去掉
        num = num.replace(",", "");//有.则去掉
    }
    if(num == 0){
        num = "";
    }
    return num;
}

/**
 * 获取屏幕高
 */
Vue.prototype.getViewHeight = function(){
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};
//日期格式化
Vue.prototype.formatDate = function (value, format) {
    return XEUtils.toDateString(value, format)
}
Vue.prototype.isBlankVue = function (obj) {
    return obj == null || obj == '' || Number(obj) == 0 || obj == undefined || obj == 'undefined' ;
}
Vue.prototype.howPoint = function (obj) {
    //console.log('obj',obj);
    if (obj != null && XEUtils.isFloat(obj)){
        //如果是小数
        //console.log('如果是小数');
        //console.log(obj,obj.toString().split(".")[1].length)
        return obj.toString().split(".")[1].length;
    }else{
        //不是小数
        return 0;
    }

}

Vue.prototype.isNumberVue = function (obj) {
    obj = Number(obj);
    return typeof obj === 'number' && !isNaN(obj) && obj !== Infinity && Number(obj) !== 0
}
Vue.prototype.isNumberZeroVue = function (obj) {
    obj = Number(obj);
    return typeof obj === 'number' && !isNaN(obj) && obj !== Infinity
}
//使用的话也很简单（乘法计算） {{NumberMul(0.0058,100)}}
//Vue浮点型乘法
Vue.prototype.NumberMul = function (arg1, arg2) {
    var m = 0;
    var s1 = arg1.toString();
    var s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        //console.log(e)
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        //console.log(e)
    }

    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
//Vue浮点型加法
Vue.prototype.NumberAdd = function (arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
}


//Vue浮点型减法
Vue.prototype.NumberSub = function (arg1, arg2) {
    var re1, re2, m, n;
    try {
        re1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        re1 = 0;
    }
    try {
        re2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        re2 = 0;
    }
    m = Math.pow(10, Math.max(re1, re2));
    n = (re1 >= re2) ? re1 : re2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//Vue浮点型除法
// 除数，被除数， 保留的小数点后的位数
Vue.prototype.NumberDiv = function (arg1, arg2, digit) {
    var t1 = 0, t2 = 0, r1, r2;
    try {
        t1 = arg1.toString().split(".")[1].length
    } catch (e) {
        console.log(e)
    }
    try {
        t2 = arg2.toString().split(".")[1].length
    } catch (e) {
        console.log(e)
    }
    r1 = Number(arg1.toString().replace(".", ""))
    r2 = Number(arg2.toString().replace(".", ""))
    //获取小数点后的计算值
    var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
    var result2 = result.split(".")[1];
    //console.log("result2.length: "+ result2.length)
    result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

    return Number(result.split(".")[0] + "." + result2);
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    //每次切换页面时，调用进度条
    //NProgress.start();
    next();
})

router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    //NProgress.done()
})
new Vue({
    router, store,
    render: h => h(App)
}).$mount('#app')


