import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
    plugins: [persistedState()],//Vuex持久化插件(vuex-persistedstate)解决刷新数据消失的问题
    // 定义的公共变量
    state: {
        roleVersion:0,
        _warehouseList: [],
        _warehouseListDefault: [],//仓库名称(全部)
        _unitList: [],//单位
        _unitListDefault: [],//单位(全部)
        _categoryList: [],//类别(筛选)
        _categoryListDefault: [],//类别(全部)
        _fullThickList: [{name:'足厚',id:1}],//足厚(筛选)
        _fullThickListDefault: [{name:'足厚',id:1}],//足厚(筛选)
        _theoryWeightList: [{name:'理计',id:1}],//理计(筛选)
        _theoryWeightListDefault: [{name:'理计',id:1}],//理计(筛选)
        _IsOrderList: [],//订货
        _IsOrderListDefault: [{name: '是', shortcut_key: 's', id: 1}, {name: '否', shortcut_key: 'f', id: 2}],
        loginAccount: {
            actionKeyList:[]
        },
        _runTimeTest: false,
        _specificationList: [],//规格 All
        _specificationListBJ: [],//规格报价 报价
        _specificationListHC: [],//规格报价 换仓

        _accountList: [],
        _CustomerAndSupplierList: [],//收款单位 付款单位
        _AllCompanyList: [],//往来单位
        _customerList: [],//查询客户 付款单位
        _supplierList: [],//收款单位
        _summaryList: [],//摘要查询
        _subjectList: [],//科目查询
        _salesManList: [],//业务员
        _salesManListAll: [],//业务员
        _billingCompanyList: [],//开单公司名
        _partOrderList: [],//开单员
        _partOrderListAll: [],//开单员
        _handledByList: [],//经手人
        _handledByListAll: [],//经手人
        _otherExpensesList: [],//其他费用
        clearingFormList: [],
        _createVoucherUpVoucherId:'',
        _createVoucherIsStart:false,
    },
    getters: {
        //当你需要改变state，并且需要经过比较复杂的计算时
        //里面的东西全都是一些方法
        //如果计算的东西，不需要组件传回参数，就直接返回一个值，
        getRoleVersion(state) {
            return state.roleVersion;
        },
        getTheoryWeightList(state) {
            return state._theoryWeightList;
        },
        getTheoryWeightListDefault(state) {
            return state._theoryWeightListDefault;
        },
        getFullThickList(state) {
            return state._fullThickList;
        },
        getFullThickListDefault(state) {
            return state._fullThickListDefault;
        },
        getWarehouseList(state) {
            return state._warehouseList;
        },
        getWarehouseListDefault(state) {
            return state._warehouseListDefault;
        },
        getUnitList(state) {
            return state._unitList;
        },
        getUnitListDefault(state) {
            return state._unitListDefault;
        },
        getCategoryList(state) {
            return state._categoryList;
        },
        getCategoryListDefault(state) {
            return state._categoryListDefault;
        },
        getIsOrderList(state) {
            return state._IsOrderList;
        },
        getIsOrderListDefault(state) {
            return state._IsOrderListDefault;
        },
        getLoginAccount(state) {
            return state.loginAccount;
        },
        getRunTimeTest(state) {
            return state._runTimeTest;
        },
        //规格
        getSpecificationList(state) {
            return state._specificationList;
        },
        //规格
        getSpecificationListBJ(state) {
            return state._specificationListBJ;
        },
        //规格
        getSpecificationListHC(state) {
            return state._specificationListHC;
        },
        getAccountList(state) {
            return state._accountList;
        },
        getCustomerAndSupplierList(state) {
            return state._CustomerAndSupplierList;
        },
        getAllCompanyList(state) {
            return state._AllCompanyList;
        },
        getCustomerList(state) {
            return state._customerList;
        },
        getSupplierList(state) {
            return state._supplierList;
        },
        getSummaryList(state) {
            return state._summaryList;
        },
        getSubjectList(state) {
            return state._subjectList;
        },
        getSalesManList(state) {
            return state._salesManList;
        },
        getSalesManListAll(state) {
            return state._salesManListAll;
        },
        getBillingCompanyList(state) {
            return state._billingCompanyList;
        },
        getPartOrderList(state) {
            return state._partOrderList;
        },
        getPartOrderListAll(state) {
            return state._partOrderListAll;
        },
        getHandledByList(state) {
            return state._handledByList;
        },
        getHandledByListAll(state) {
            return state._handledByListAll;
        },
        getOtherExpensesList(state) {
            return state._otherExpensesList;
        },
        getClearingFormList(state) {
            return state.clearingFormList;
        },
        getCreateVoucherUpVoucherId(state) {
            return state._createVoucherUpVoucherId;
        },
        getCreateVoucherIsStart(state) {
            return state._createVoucherIsStart;
        },
    },
    // state中的变量只能在mutations中通过方法修改
    mutations: {
        updateRoleVersion(state, roleVersion) {
            state.roleVersion = roleVersion;
        },
        updateTheoryWeightList(state, _theoryWeightList) {
            state._theoryWeightList = _theoryWeightList;
        },
        updateTheoryWeightListDefault(state, _theoryWeightListDefault) {
            state._theoryWeightListDefault = _theoryWeightListDefault;
        },
        updateFullThickList(state, _fullThickList) {
            state._fullThickList = _fullThickList;
        },
        updateFullThickListDefault(state, _fullThickListDefault) {
            state._fullThickListDefault = _fullThickListDefault;
        },
        updateWarehouseList(state, _warehouseList) {
            state._warehouseList = _warehouseList;
        },
        updateWarehouseListDefault(state, _warehouseListDefault) {
            state._warehouseListDefault = _warehouseListDefault;
        },
        updateUnitList(state, _unitList) {
            state._unitList = _unitList;
        },
        updateUnitListDefault(state, _unitListDefault) {
            state._unitListDefault = _unitListDefault;
        },
        updateIsOrderList(state, _IsOrderList) {
            state._IsOrderList = _IsOrderList;
        },
        updateIsOrderListDefault(state, _IsOrderListDefault) {
            state._IsOrderListDefault = _IsOrderListDefault;
        },
        updateLoginAccount(state, loginAccount) {
            state.loginAccount = loginAccount;
        },
        updateRunTimeTest(state, _runTimeTest) {
            state._runTimeTest = _runTimeTest;
        },
        updateSpecificationList(state, _specificationList) {
            state._specificationList = _specificationList;
        },
        updateSpecificationListBJ(state, _specificationListBJ) {
            state._specificationListBJ = _specificationListBJ;
        },
        updateSpecificationListHC(state, _specificationListHC) {
            state._specificationListHC = _specificationListHC;
        },
        updateAccountList(state, _accountList) {
            state._accountList = _accountList;
        },
        updateCustomerAndSupplierList(state, _CustomerAndSupplierList) {
            state._CustomerAndSupplierList = _CustomerAndSupplierList;
        },
        updateAllCompanyList(state, _AllCompanyList) {
            state._AllCompanyList = _AllCompanyList;
        },
        updateCustomerList(state, _customerList) {
            state._customerList = _customerList;
        },
        updateSupplierList(state, _supplierList) {
            state._supplierList = _supplierList;
        },
        updateSummaryList(state, _summaryList) {
            state._summaryList = _summaryList;
        },
        updateSubjectList(state, _subjectList) {
            state._subjectList = _subjectList;
        },
        updateSalesManList(state, _salesManList) {
            state._salesManList = _salesManList;
        },
        updateSalesManListAll(state, _salesManListAll) {
            state._salesManListAll = _salesManListAll;
        },
        updateBillingCompanyList(state, _billingCompanyList) {
            state._billingCompanyList = _billingCompanyList;
        },

        updatePartOrderList(state, _partOrderList) {
            state._partOrderList = _partOrderList;
        },
        updatePartOrderListAll(state, _partOrderListAll) {
            state._partOrderListAll = _partOrderListAll;
        },
        updateHandledByList(state, _handledByList) {
            state._handledByList = _handledByList;
        },
        updateHandledByListAll(state, _handledByListAll) {
            state._handledByListAll = _handledByListAll;
        },
        updateOtherExpensesList(state, _otherExpensesList) {
            state._otherExpensesList = _otherExpensesList;
        },
        updateClearingFormList(state, clearingFormList) {
            state.clearingFormList = clearingFormList;
        },
        updateCategoryList(state, _categoryList) {
            state._categoryList = _categoryList;
        },
        updateCategoryListDefault(state, _categoryListDefault) {
            state._categoryListDefault = _categoryListDefault;
        },
        //当你需要改变state并且同步的时候在这里
        updateCreateVoucherUpVoucherId(state, _createVoucherUpVoucherId) {
            state._createVoucherUpVoucherId = _createVoucherUpVoucherId;
        },
        updateCreateVoucherIsStart(state, _createVoucherIsStart) {
            state._createVoucherIsStart = _createVoucherIsStart;
        },
    },
    actions: {
        //这里的东西全都是异步的
        asyncUpdateRoleVersion(context, roleVersion) {
            context.commit('updateRoleVersion', roleVersion);
        },
        asyncUpdateTheoryWeightList(context, _theoryWeightList) {
            context.commit('updateTheoryWeightList', _theoryWeightList);
        },
        asyncTheoryWeightListDefault(context, _theoryWeightListDefault) {
            context.commit('updateTheoryWeightListDefault', _theoryWeightListDefault);
        },
        asyncUpdateFullThickList(context, _fullThickList) {
            context.commit('updateFullThickList', _fullThickList);
        },
        asyncFullThickListDefault(context, _fullThickListDefault) {
            context.commit('updateFullThickListDefault', _fullThickListDefault);
        },
        asyncUpdateWarehouseList(context, _warehouseList) {
            context.commit('updateWarehouseList', _warehouseList);
        },
        asyncUpdateWarehouseListDefault(context, _warehouseListDefault) {
            context.commit('updateWarehouseListDefault', _warehouseListDefault);
        },
        asyncUpdateUnitList(context, _unitList) {
            context.commit('updateUnitList', _unitList);
        },
        asyncUpdateUnitListDefault(context, _unitListDefault) {
            context.commit('updateUnitListDefault', _unitListDefault);
        },
        asyncUpdateIsOrderList(context, _IsOrderList) {
            context.commit('updateIsOrderList', _IsOrderList);
        },
        asyncUpdateIsOrderListDefault(context, _IsOrderListDefault) {
            context.commit('updateIsOrderListDefault', _IsOrderListDefault);
        },
        asyncUpdateLoginAccount(context, loginAccount) {
            context.commit('updateLoginAccount', loginAccount);
        },
        asyncUpdateRunTimeTest(context, _runTimeTest) {
            context.commit('updateRunTimeTest', _runTimeTest);
        },
        asyncUpdateSpecificationList(context, _specificationList) {
            context.commit('updateSpecificationList', _specificationList);
        },
        asyncUpdateSpecificationListBJ(context, _specificationListBJ) {
            context.commit('updateSpecificationListBJ', _specificationListBJ);
        },
        asyncUpdateSpecificationListHC(context, _specificationListHC) {
            context.commit('updateSpecificationListHC', _specificationListHC);
        },
        asyncUpdateAccountList(context, _accountList) {
            context.commit('updateAccountList', _accountList);
        },
        asyncUpdateCustomerAndSupplierList(context, _CustomerAndSupplierList) {
            context.commit('updateCustomerAndSupplierList', _CustomerAndSupplierList);
        },
        asyncUpdateAllCompanyList(context, _AllCompanyList) {
            context.commit('updateAllCompanyList', _AllCompanyList);
        },
        asyncUpdateCustomerList(context, _customerList) {
            context.commit('updateCustomerList', _customerList);
        },
        asyncUpdateSupplierList(context, _supplierList) {
            context.commit('updateSupplierList', _supplierList);
        },
        asyncUpdateSummaryList(context, _summaryList) {
            context.commit('updateSummaryList', _summaryList);
        },
        asyncUpdateSubjectList(context, _subjectList) {
            context.commit('updateSubjectList', _subjectList);
        },
        asyncUpdateSalesManList(context, _salesManList) {
            context.commit('updateSalesManList', _salesManList);
        },
        asyncUpdateSalesManListAll(context, _salesManListAll) {
            context.commit('updateSalesManListAll', _salesManListAll);
        },
        asyncUpdateBillingCompanyList(context, _billingCompanyList) {
            context.commit('updateBillingCompanyList', _billingCompanyList);
        },
        asyncUpdatePartOrderList(context, _partOrderList) {
            context.commit('updatePartOrderList', _partOrderList);
        },
        asyncUpdatePartOrderListAll(context, _partOrderListAll) {
            context.commit('updatePartOrderListAll', _partOrderListAll);
        },
        asyncUpdateHandledByList(context, _handledByList) {
            context.commit('updateHandledByList', _handledByList);
        },
        asyncUpdateHandledByListAll(context, _handledByListAll) {
            context.commit('updateHandledByListAll', _handledByListAll);
        },
        asyncUpdateOtherExpensesList(context, _otherExpensesList) {
            context.commit('updateOtherExpensesList', _otherExpensesList);
        },
        asyncUpdateClearingFormList(context, clearingFormList) {
            context.commit('updateClearingFormList', clearingFormList);
        },
        asyncUpdateCategoryList(context, _categoryList) {
            context.commit('updateCategoryList', _categoryList);
        },
        asyncUpdateCategoryListDefault(context, _categoryListDefault) {
            context.commit('updateCategoryListDefault', _categoryListDefault);
        },
        asyncUpdateCreateVoucherUpVoucherId(context, _createVoucherUpVoucherId) {
            context.commit('updateCreateVoucherUpVoucherId', _createVoucherUpVoucherId);
        },
        asyncUpdateCreateVoucherIsStart(context, _createVoucherIsStart) {
            context.commit('updateCreateVoucherIsStart', _createVoucherIsStart);
        },
    }

})
