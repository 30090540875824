<template>
    <div id="app" v-cloak>
		<div id="nav" v-show="islogin" v-cloak>
            <TOP></TOP>
        </div>
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>
<script>
    import TOP from "@/views/common/TOP";
    export default {
        components: {TOP},
        data() {
            return {
                islogin:false
            };
        },
        watch:{
            '$route.path': {
                handler:function(val) {
                    //console.log(val,'lll')
                    // 处理路由参数变化后的操作
                    if( val =='/login' ){
                        //console.log(val,'val')
                        this.islogin = false;
                    }   else {
                        this.islogin = true;
                    }
                },
                // 当$route.params改变时，立即触发handler方法
                // immediate: true,
                // deep: true,
            },
        },
        mounted() {
            /* let start = window.location.href.lastIndexOf('/');
             let path = window.location.href.slice(start+1);
             this.activeIndex = path;*/
            //console.log(path)
        },
        /*mounted() {
            if (this.$_isMobile()) {
                alert("手机端");
                //this.$router.replace('/mob');
            } else {
                alert("pc端");
                //this.$router.replace('/index.html');
            }
        },*/
        //window.sessionStorage.getItem('sessionId')
        //未完成，用来判断是否测试环境
        created() {
            //console.log('runTime:',sessionStorage.getItem("runTime"));
            this.$_findRoleVersion();
            this.$_isChangeSpecification();
            /*this.$_searchCustomerList('');
            this.$_searchCategoryList('');
            this.$_searchUnitList('');*/

       }
   }
</script>

<style>
    [v-cloak] {
        display: none;
    }

    body {
        margin: initial;
   }

    .el-select-dropdown__item{
        /*font-size: 18px;*/
        padding: 0 10px
    }
    /*.el-autocomplete-suggestion li{
        font-size: 18px;
    }*/

/*    th, td {
        text-align: center !important;
   }*/

    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    .pull-right {
        float: right !important;
   }

    .pull-left {
        float: left !important;
   }

    .pull-left-text {
        text-align: left !important;
   }

    .vxe-toolbar .vxe-custom--option-wrapper {
        right: initial !important;
   }

/*    .vxe-toolbar .vxe-custom--option-wrapper .vxe-custom--footer {
        display: none;
   }*/

    .el-menu.el-menu--horizontal {
        border-bottom: initial;
   }

    .el-menu--horizontal > .el-menu-item {
        height: 30px;
        line-height: 30px;
   }

    .el-menu--horizontal > .el-submenu .el-submenu__title {
        height: 30px;
        line-height: 30px;
   }

    .el-tabs__header {
        margin: initial;
   }

    .el-table .warning-row {
        background: oldlace;
   }

    .success-row {
        background: #f0f9eb;
   }

    .red-row {
        color:#ff2f2f
    }

    .green-row {
        background: #67ffc4;
   }
    .main-row {
        background: #ffe5e54a;
   }

    .el-form-item {
        margin-bottom: initial;
   }

    .warning {
        padding: 8px 16px;
        background-color: #fff6f7;
        border-radius: 4px;
        border-left: 5px solid #fe6c6f;
        /*margin: 20px 0;*/
   }

    .tip {
        padding: 2px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #50bfff;
   }


    .el-tabs__item {
        height: 30px;
        line-height: 30px;
   }

    .el-container {
        display: initial !important;
   }

    .myAutocomplete {
        width: auto !important;
   }

    .el-input__suffix {
        right: 0px !important;
   }

    .el-input--suffix .el-input__inner {
        padding-right: initial;
   }

    .el-select .el-input__inner {
        padding-right: initial;
   }

    table .el-input__icon {
        width: initial;
   }

    .vxe-input .vxe-input--date-picker-icon {
        left: initial;
   }

    .el-input-number.is-without-controls .el-input__inner {
        padding-left: 1px;
        padding-right: 1px;
   }

    .unEditor {
        background-color: #EEEEEE;
   }

    .vxe-table--render-default .vxe-body--row.row--current .unEditor {
        background-color: #e6f7ff;
   }

    .vxe-table--render-default .vxe-body--row.row--hover .unEditor {
        background-color: #f0f9eb;
   }

    .vxe-table--render-default .vxe-body--row.row--hover, .vxe-table--render-default .vxe-body--row.row--hover.row--stripe {
        background-color: #f0f9eb;
   }


    /*  table .el-input__inner{
        height: 28px;
        line-height: 28px;
        padding: initial;
     }
      */
    table .el-input__inner {
        height: 28px;
        line-height: 28px;
   }



    table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 20px;
        line-height: 20px;
   }

    table .el-input--mini .el-input__icon {
        line-height: 20px;
   }

    table input::-webkit-outer-spin-button,
    table input::-webkit-inner-spin-button {
        -webkit-appearance: none;
   }

    table input[type="number"] {
        -moz-appearance: textfield;
   }

    table i.el-input__icon {
        line-height: initial;
   }

    .vxe-table--render-default, .el-input__inner {
        color: #000000 !important;
        font-weight: 500;
   }


    .el-form-item__content > .el-input input[readonly],.vxe-input > input[readonly] {
        background-color: #EEEEEE;
   }

    .vxe-body--column.col-success {
        cursor: pointer;
        background-color: #7fbcfb;
        color: #fff;
   }

    .vxe-body--column.col-blue {
        cursor: pointer;
        background-color: #e6f7ff;
        color: #000000;
   }
    .vxe-body--column.invoice-col-blue {
        cursor: pointer;
        font-size: 12px !important;
        background-color: #e6f7ff;
        color: #000000;
   }
    .vxe-body--column.col-fail {
        cursor: pointer;
        background-color: #ffe7e7;
        color: #000000;
   }
    .vxe-body--column.col-yellow {
        cursor: pointer;
        background-color: #ffc563;
        color: #000000;
   }
    .vxe-body--column.col-ableClick {
        cursor: pointer;
        background-color: #fff1f1;
        color: #000000;
   }
    .pointer{
        cursor: pointer;
    }

    .selectedColor{
        background-color: #e6f7ff;
   }

    .sumColorRed{
        background-color: #ffe6e6;
    }

    .vxe-input--clear-icon.vxe-icon--close {
        height: 2.2em;
   }



    /*.vxe-table--render-default.border--full .vxe-body--column, .vxe-table--render-default.border--full .vxe-footer--column, .vxe-table--render-default.border--full .vxe-header--column{
        background-image: linear-gradient(#000000,#000000),linear-gradient(#000000,#000000) !important;;
        background-position:initial !important;
        background-size: 0.6px 100%,100% 0.6px !important;
   }*/

    .vxe-header--column, .vxe-body--column{
        background-image: linear-gradient(#000000,#000000),linear-gradient(#000000,#000000) !important;;
        background-position:initial !important;
        background-size: 0.6px 100%,100% 0.6px !important;
   }
    .jine_td {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANsAAAAUCAMAAAAz47gTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9QTFRF6enpp9n17bm55ubmvJyc+JGLrgAAAAV0Uk5T/////wD7tg5TAAAAQklEQVR42uzPsQ0AIAwDsBb4/2YOYCkCqYszRsngWGdmscum7yju4qHLpu/47GBjY2NjY2NjY2NjY2NjY7u3bQEGAIG/Qbbiioa2AAAAAElFTkSuQmCC) !important;
        background-repeat: repeat-y !important;
        background-size: initial !important;
        background-position: initial !important;
        border-bottom: #cccccc solid 1px !important;
        border-right: #cccccc solid 1px !important;
   }
    .jine_td > div {
        letter-spacing: 10px !important;
        text-align: right !important;
   }

    .jine_td_red {
        color: red !important;
   }

    .vxe-table--header-wrapper{
        border-top:1px solid #000 !important;
   }

    .vxe-body--row{
        font-size: 17px !important;
   }

    .vxe-input--inner {
        padding: 0 5px !important;
   }

/*    .el-input__inner {
        padding: 0 5px !important;
   }*/

   /* .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 24px !important;
   }
    .vxe-table--render-default.size--mini .vxe-cell .vxe-default-input, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-select, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-textarea{
        height: 24px !important;
   }
    .vxe-input--clear-icon.vxe-icon--close {
        height: 1.2em !important;
   }
    .vxe-input.size--mini {
        height: 24px !important;
   }
    table .el-input__inner {
        height: 24px !important;
        line-height: 24px !important;
   }
    .vxe-table--render-default .vxe-body--column, .vxe-table--render-default .vxe-footer--column, .vxe-table--render-default .vxe-header--column {
        line-height: 20px !important;
   }
    .vxe-button.size--mini.type--button.is--circle {
        min-width: 22px !important;
   }
    .vxe-button.size--mini.type--button {
        height: 22px !important;
   }
    .vxe-button.size--mini{
        font-size: 10px !important;
   }*/

    .vxe-cell-help-icon.vxe-icon--question{
        display: -webkit-box !important;
        position: absolute !important;
        right: 1% !important;
    }
</style>
