import XEUtils from "xe-utils";

export default {
    data() {
        return {
            isChangeSpecification:0,
            showFrom: true,
            isMobile: true,
            //打磨驳口
            polishRoll:[
                { "value": "打磨"},
                { "value": "驳口"},
                { "value": "打磨驳口"},
            ],
        }
    },
    created() {
        /*if (this.$_isMobile()) {
            this.isMobile = true;
            this.showFrom = false;
        } else {
            this.isMobile = false;
            this.showFrom = true;
        }*/
    },
/*    beforeDestroy() {
        // 组件销毁时清理
        clearTimeout(this.debounceTimer);
        this.cancelToken?.cancel('组件销毁取消请求');
    },*/
    methods: {
        /**
         * 获取当前月的第一天
         */
        $getMonthFirst(date) {
            var now = new Date(date);
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            return new Date(year + '-' + month + '-01 00:00:00');
        },
        /**
         * 获取的最后一天
         */
        $getMonthLast(date) {
            var currentMonth = new Date(date).getMonth();
            var nextMonth = ++currentMonth;
            var nextMonthFirstDay = new Date(new Date(date).getFullYear(), nextMonth, 1);
            var oneDay = 1000 * 60 * 60 * 24;
            return new Date(nextMonthFirstDay - oneDay);
        },
        /**
         * 公式计算逻辑 start
         * @param row
         */
        //所有触发计算逻辑
        $_singleCountEvent(row) {

            //console.log(row);
            //计算行理论重量
            this.$_getTheoreticalWeightSingle(row);
            //单价设置到 每支单价
            this.$_setSinglePrice(row);
            //单价设置到 吨价
            this.$_setTonsOfPrice(row);
            //计算行产品金额
            this.$_getAmountOfProductEvent(row);
            /*
            用于正式设置
            每支单价
            吨价
             */
            //单价设置到 每支单价
            this.$_setSinglePrice(row);
            //单价设置到 吨价
            this.$_setTonsOfPrice(row);
            /*
            end
            用于正式设置
            每支单价
            吨价
             */
            //计算行 支重
            this.$_getSingleWeight(row);
            //实收单价
            this.$_setSinglePricePractical(row);
            //计算行 实际金额
            this.$_getPracticalPriceEvent(row);

            //计算行 佣金
            this.$_getKickBack(row); //先计算佣金，产品裸价依赖佣金
            //计算行 产品裸价
            this.$_setNakedPrice(row);
            //计算行 实际销售金额
            this.$_setActualSalesAmount(row);
            //计算行 实际销售单价
            this.$_setActualSinglePrice(row);
            //计算行 实际吨价
            this.$_setTonsOfPricePractical(row);//实际吨价 依赖 产品裸价
            this.$_setActualAPrice(row);
            this.$_setActualAPriceNoTax(row);
            this.$_setActualTonPrice(row);
            //计算行 税费  计算每行税费
            this.$_setTaxes(row);

            //计算行 理论支数
            this.$_getTheTheoryOfCount(row);
        },
        //删除的话，每一行都计算一遍，用于修改头部 税率等导致需要重新计算每行的值 销售开单
        $_singleCountEventEvery() {
            for (var t in this.tableDataMain) {
                this.$_singleCountEvent(this.tableDataMain[t])
            }
            this.$_sumTotalWeighingHeavy();//平均杂费之前，需要合计总过磅总量
            this.$_getFeesEveryRow();//行内如果重量发生变动，对应的杂费也自动修改
            this.$_sumTopSome();
        },
        $_singleCountEventAndSumTopSome(row) {
            //console.log('$_singleCountEventAndSumTopSome')
            this.$_singleCountEvent(row);
            this.$_sumTotalWeighingHeavy();//平均杂费之前，需要合计总过磅总量
            this.$_getFeesEveryRow();//行内如果重量发生变动，对应的杂费也自动修改
            this.$_sumTopSome();
            this.$_singleCountEventEvery();
        },
        //订单日期是否清空
        $_singleCountEventCleandate(row){
            //console.log(row.single_count_temp)
            if (row.single_count_temp != null && ((row.single_count_temp < 0 && row.single_count > 0) || (row.single_count_temp  > 0 && row.single_count < 0))){
                row.stock_bill_date_dd = '';
            }
        },
        //产品金额输入，设置单价
        $_amountOfProductTOChangeSinglePrice(row){
            if (row.unit == '支') {
                row.price = ''
                //单价= 产品金额 ÷ 支数
                //产品金额 amountOfProduct
                //支数 singleCount
                if (!this.isBlankVue(row.amount_of_product) && !this.isBlankVue(row.single_count)) {
                    row.price = this.$XEUtils.round(this.$XEUtils.divide(row.amount_of_product, row.single_count), 5);
                }
            } else if (row.unit == '吨') {
                row.price = ''
                //单价 = 产品金额 ÷ 过磅重
                if (!this.isBlankVue(row.amount_of_product) && !this.isBlankVue(row.weighing_heavy)) {
                    row.price = this.$XEUtils.round(this.$XEUtils.divide(row.amount_of_product, row.weighing_heavy), 5);
                }
            }else{
                row.price = ''
            }
        },
        //产品金额输入，设置实收单价
        $_practicalPriceTOChangeSinglePricePractical(row){
            if (row.unit == '支') {
                row.single_price_practical = ''
                if (!this.isBlankVue(row.practical_price) && !this.isBlankVue(row.single_count)) {
                    row.single_price_practical = this.$XEUtils.round(this.$XEUtils.divide(row.practical_price, row.single_count), 5);
                }
            } else if (row.unit == '吨') {
                row.single_price_practical = ''
                //单价 = 产品金额 ÷ 过磅重
                if (!this.isBlankVue(row.practical_price) && !this.isBlankVue(row.weighing_heavy)) {
                    row.single_price_practical = this.$XEUtils.round(this.$XEUtils.divide(row.practical_price, row.weighing_heavy), 5);
                }
            }else{
                row.single_price_practical = ''
            }
        },
        //税金
        $_taxTateWarehouseBillEvent() {
            //console.log('taxesFeesSecondStringEvent');
            if (this.isBlankVue(this.formData.tax_tate_warehouse_bill)) {
                this.formData.tax_tate_warehouse_bill = '';
            }
            if (this.isBlankVue(this.formData.other_tax)) {
                this.formData.other_tax = '';
            }
            if (!this.isBlankVue(this.formData.taxes_fees_second_string) && (!this.isBlankVue(this.formData.tax_tate_warehouse_bill) || !this.isBlankVue(this.formData.other_tax))) {
                /* setTimeout(() => {
                     this.formData.tax_tate_warehouse_bill = 0;
                }, 500)*/
                // this.$XModal.alert({content: '后补税金已存在，不能使用税率！', maskClosable: true})
                this.$XModal.alert('后补税金已存在，不能使用税率！').then(() => {
                    //const $grid = this.$refs.xGrid
                    this.formData.tax_tate_warehouse_bill = 0;
                    this.formData.other_tax = 0;
                    this.$_setTaxTateStockRow();
                    this.$_singleCountEventEvery();
                    //this.$_sumTopSome();
                })
            } else {
                this.$_sumTopSome();
            }
        },
        //计算头部数据，只负责把每一行的值合计
        $_sumTopSome() {
            //合计支数
            this.$_sumSingleCount();
            //合计整个表格的理论重量
            this.$_sumTheoreticalWeight();
            //进仓单、销售开单 合计金额
            this.$_sumTotalAmount();
            //合计产品金额
            this.$_sumAmountOfProduct(); // 不知道哪里需要的，销售开单没有这个字段
            //合计实收金额
            this.$_sumPracticalTotalPrice();
            //合计佣金
            this.$_sumKickBackTotal();
            //合计总过磅重
            this.$_sumTotalWeighingHeavy();

            //进仓单 合计中转费
            //this.$_sumTranshipmentCharges();
            //进仓单 合计附加费
            //this.$_sumAdditionalCharges();

            //平均(在头部change再触发) 计算每行磅费、风割费、装车费、中转费、运费 、计算每行税费，数据从头部÷到每一行
            //this.$_getFeesEveryRow();
            //计算每行 产品裸价、计算每行 实际吨价、产品税金、合计裸价 只负责把每一行的值合计
            this.$_getNakedPriceEveryRow();
            //计算杂费税金
            this.$_setOtherTaxMoney();
            //合计杂费费用
            this.$_sumAllFees();
            //合计税金
            this.$_sumTaxesFees();
            //由底部合计各类杂费
            this.$_sumFeesEveryRow();
            //每行外省运费裸价、每行外省运费税金
            this.$_getNakedOtherFreightFeesTaxMoneyEveryRow();
            //每行进仓成本 依赖：每行外省运费裸价  每行产品裸价
            this.$_getNakedIntoWarehouseCostEveryRow();
            //合计 进仓单 成本 合计到头部进仓成本
            this.$_sumNakedIntoWarehouseCost();
            //进仓单 计算 报价单吨价 依赖：每行外省运费、各类杂费
            this.$_getTonsOfPriceBJEveryRow();
            this.$_getSinglePriceBJEveryRow();
            //计算头部合计 实际销售金额
            this.$_setActualSalesAmountBill();
            /*console.log('xGrid',this.$refs['xGrid'])
            console.log('baseGird',this.$refs['baseGird'])*/
            //console.log('baseGird',this.$refs['baseGird'])
            //console.log('this.$refs[\'xGrid\'].$refs.baseGird',this.$refs['xGrid'].$refs.baseGird)

            //更新底部
            if (!this.isBlankVue(this.$refs['baseGird'])) {
                this.$refs['baseGird'].updateFooter();
            }
            if (!this.isBlankVue(this.$refs.xGrid)) {
                this.$refs.xGrid.$refs.baseGird.updateFooter();
            }
        },
        //合计总过磅重
        $_sumTotalWeighingHeavy() {
            var totalWeighingHeavyAll = Number(0);
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (this.tableDataMain[t].weighing_heavy != null) {
                    totalWeighingHeavyAll = this.$XEUtils.add(totalWeighingHeavyAll, Number(this.tableDataMain[t].weighing_heavy));
                }
            }
            this.formData.total_weighing_heavy = totalWeighingHeavyAll;

        },

        //合计实收金额
        $_sumPracticalTotalPrice() {
            let practicalTotalPriceAll = 0;
            for (let t in this.tableDataMain) {

                //console.log("this.tableDataMain[t].practical_price");
                //console.log(this.tableDataMain[t].practical_price);
                if (this.tableDataMain[t].practical_price != null) {
                    //practicalTotalPriceAll = this.NumberAdd(practicalTotalPriceAll, Number(this.tableDataMain[t].practical_price));
                    practicalTotalPriceAll = this.$XEUtils.add(practicalTotalPriceAll, this.tableDataMain[t].practical_price);
                }
            }
            //包费用，实收需要减去 佣金
            /* if (this.formData.is_contract_fees == 0){
               practicalTotalPriceAll = this.$XEUtils.subtract(this.formData.total_amount,this.formData.kick_back_total);
             }*/
            //console.log('$_sumPracticalTotalPrice',this.$XEUtils.round(this.$XEUtils.add(6750,1824.9),2));
            //console.log(practicalTotalPriceAll,'practicalTotalPriceAll',this.$XEUtils.round(practicalTotalPriceAll,2));
            //如果后补税金不为空，则把后补税金加上去
            if(!this.isBlankVue(this.formData.taxes_fees_second_string)){
                practicalTotalPriceAll = Number(practicalTotalPriceAll + Number(this.formData.taxes_fees_second_string));
            }
            this.formData.practical_total_price = this.$XEUtils.round(Number(practicalTotalPriceAll), 2);
        },
        //合计 佣金
        $_sumKickBackTotal() {
            let kickBackTotal = Number(0);
            for (let t in this.tableDataMain) {
                if (this.tableDataMain[t].kick_back != null) {
                    //kickBackTotal = this.NumberAdd(kickBackTotal, Number(this.tableDataMain[t].kick_back));
                    kickBackTotal = this.$XEUtils.add(kickBackTotal, Number(this.tableDataMain[t].kick_back));
                }
            }
            this.formData.kick_back_total = this.$XEUtils.round(Number(kickBackTotal), 2);
        },
        //合计 进仓单 成本
        $_sumNakedIntoWarehouseCost() {
            let nakedIntoWarehouseCost = Number(0);
            for (let t in this.tableDataMain) {
                if (this.tableDataMain[t].naked_into_warehouse_cost != null) {
                    //kickBackTotal = this.NumberAdd(kickBackTotal, Number(this.tableDataMain[t].kick_back));
                    nakedIntoWarehouseCost = this.$XEUtils.add(nakedIntoWarehouseCost, Number(this.tableDataMain[t].naked_into_warehouse_cost));
                }
            }
            this.formData.naked_into_warehouse_cost = this.$XEUtils.round(Number(nakedIntoWarehouseCost), 2);
        },
        //设置每行税率
        $_setTaxTateStockRow() {
            for (let t in this.tableDataMain) {
                if (!this.isBlankVue(this.tableDataMain[t].specification) && this.isNumberVue(this.formData.tax_tate_warehouse_bill)){
                    this.tableDataMain[t].tax_tate_stock = this.formData.tax_tate_warehouse_bill;
                }
            }
        },
        //检测每行税率是否相等
        $_checkTaxTateStockRow() {
            let taxSet = [];
            for (let t in this.tableDataMain) {
                if (!this.isBlankVue(this.tableDataMain[t].specification) ){
                    if (!this.isBlankVue(this.tableDataMain[t].tax_tate_stock)){
                        taxSet.push(Number(this.tableDataMain[t].tax_tate_stock));
                    }else{
                        taxSet.push(Number(0));
                    }
                }
            }
            taxSet = Array.from(new Set(taxSet));
            if (taxSet.length > 1){
                this.formData.tax_tate_warehouse_bill = '行内各异';
            }else{
                this.formData.tax_tate_warehouse_bill = taxSet[0];
            }
        },
        //合计整个表格的理论重量
        $_sumTheoreticalWeight() {
            //const $grid = this.$refs.tableDataMainXGrid
            let theoreticalWeightAll = Number(0);
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (this.tableDataMain[t].theoretical_weight != null) {
                    //theoreticalWeightAll = this.NumberAdd(theoreticalWeightAll, Number(this.tableDataMain[t].theoretical_weight));
                    theoreticalWeightAll = this.$XEUtils.add(theoreticalWeightAll, Number(this.tableDataMain[t].theoretical_weight));
                }
            }
            this.formData.total_theoretical_weight = this.$XEUtils.round(Number(theoreticalWeightAll), 6);
            //console.log(theoreticalWeightAll);
        },
        //合计支数
        $_sumSingleCount() {
            var singleCountAll = Number(0);
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (this.tableDataMain[t].single_count != null) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    singleCountAll = this.$XEUtils.add(singleCountAll, this.tableDataMain[t].single_count);
                }
            }
            this.formData.total_number = singleCountAll;
        },
        //计算每行产品裸价 = 产品金额 - 佣金 - 税费 - 各类费用
        $_getNakedPriceEveryRow() {
            //判断是否包费用，包费用才需要 减去费用
            this.formData.naked_price_total = 0;
            for (var t in this.tableDataMain) {
                //计算每行裸价
                //this.$_setNakedPrice(this.tableDataMain[t]);
                //销售开单 实际吨价 = 产品裸价 ÷ 过磅重
                //只负责把每一行的值合计
                //this.$_setTonsOfPricePractical(this.tableDataMain[t]);
                if (!this.isBlankVue(this.tableDataMain[t].naked_price )) {
                    this.formData.naked_price_total = this.$XEUtils.add(this.formData.naked_price_total, this.tableDataMain[t].naked_price );
                }
            }
            this.formData.naked_price_total = this.$XEUtils.round(this.formData.naked_price_total,2);
            //$_setTableTaxes(tableDataMain,formData.tax_tate_warehouse_bill),
        },
        //计算每行进仓成本 = 每行产品裸价 + 每行外省运费裸价
        $_getNakedIntoWarehouseCostEveryRow() {
            for (var t in this.tableDataMain) {
                this.tableDataMain[t].naked_into_warehouse_cost = this.$XEUtils.round(this.$XEUtils.add(this.tableDataMain[t].naked_price , this.tableDataMain[t].naked_other_freight_fees_tax_money),2);
                if (this.isBlankVue(this.tableDataMain[t].naked_into_warehouse_cost)){
                    this.tableDataMain[t].naked_into_warehouse_cost = '';
                }
            }
            //$_setTableTaxes(tableDataMain,formData.tax_tate_warehouse_bill),
        },
        //计算每行 报价单吨价 = （（ 产品金额（含税） + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 外省运费（含税））÷ 过磅重 ） + 10元
        //(不含税吨价)报价单吨价 = (成本 + 磅费 + 风割费 + 装车费 + 中转费 + 运费 ) ÷ 过磅重 + 10元
        $_getTonsOfPriceBJEveryRow(){
            for (var t in this.tableDataMain) {
                this.tableDataMain[t].tons_of_price_bj = XEUtils.reduce([this.tableDataMain[t].naked_into_warehouse_cost, this.tableDataMain[t].pounds_fees,
                    this.tableDataMain[t].wind_cut_fees, this.tableDataMain[t].loading_car_fees, this.tableDataMain[t].transfer_fees,
                    this.tableDataMain[t].freight_fees,this.tableDataMain[t].procedure_fees], (previous, item) => this.$XEUtils.add(previous,item));
                if (!this.isBlankVue(this.tableDataMain[t].tons_of_price_bj) && !this.isBlankVue(this.tableDataMain[t].weighing_heavy)){
                    this.tableDataMain[t].tons_of_price_bj = this.$XEUtils.round(this.$XEUtils.divide(this.tableDataMain[t].tons_of_price_bj, this.tableDataMain[t].weighing_heavy),3);
                    if (this.isBlankVue(this.tableDataMain[t].tons_of_price_bj)){
                        this.tableDataMain[t].tons_of_price_bj = '';
                    }else{
                        this.tableDataMain[t].tons_of_price_bj = this.$XEUtils.add(this.tableDataMain[t].tons_of_price_bj,10);
                    }
                }else{
                    this.tableDataMain[t].tons_of_price_bj = '';
                }
            }
        },
        /*$_getTonsOfPriceBJEveryRow(){
            for (var t in this.tableDataMain) {
                this.tableDataMain[t].tons_of_price_bj = XEUtils.reduce([this.tableDataMain[t].amount_of_product, this.tableDataMain[t].pounds_fees,
                    this.tableDataMain[t].wind_cut_fees, this.tableDataMain[t].loading_car_fees, this.tableDataMain[t].transfer_fees,
                    this.tableDataMain[t].freight_fees, this.tableDataMain[t].other_freight_fees], (previous, item) => this.$XEUtils.add(previous,item));
                if (!this.isBlankVue(this.tableDataMain[t].tons_of_price_bj) && !this.isBlankVue(this.tableDataMain[t].weighing_heavy)){
                    this.tableDataMain[t].tons_of_price_bj = this.$XEUtils.round(this.$XEUtils.divide(this.tableDataMain[t].tons_of_price_bj, this.tableDataMain[t].weighing_heavy),3);
                    if (this.isBlankVue(this.tableDataMain[t].tons_of_price_bj)){
                        this.tableDataMain[t].tons_of_price_bj = '';
                    }else{
                        this.tableDataMain[t].tons_of_price_bj = this.$XEUtils.add(this.tableDataMain[t].tons_of_price_bj,10);
                    }
                }else{
                    this.tableDataMain[t].tons_of_price_bj = '';
                }
            }
        },*/
        //计算每行 报价单支价 = （（ 产品金额（含税） + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 外省运费（含税）+ 10元 ）÷ 支数 ）
        $_getSinglePriceBJEveryRow(){
            for (var t in this.tableDataMain) {
                this.tableDataMain[t].single_price_bj = XEUtils.reduce([this.tableDataMain[t].naked_into_warehouse_cost, this.tableDataMain[t].pounds_fees,
                    this.tableDataMain[t].wind_cut_fees, this.tableDataMain[t].loading_car_fees, this.tableDataMain[t].transfer_fees,
                    this.tableDataMain[t].freight_fees,this.tableDataMain[t].procedure_fees,10], (previous, item) => this.$XEUtils.add(previous,item));
                //console.log(this.tableDataMain[t].single_price_bj);
                if (!this.isBlankVue(this.tableDataMain[t].single_price_bj) && !this.isBlankVue(this.tableDataMain[t].single_count)){
                    this.tableDataMain[t].single_price_bj = this.$XEUtils.round(this.$XEUtils.divide(this.tableDataMain[t].single_price_bj, this.tableDataMain[t].single_count),2);
                }else{
                    this.tableDataMain[t].single_price_bj = '';
                }
            }
        },
        /*$_getSinglePriceBJEveryRow(){
            for (var t in this.tableDataMain) {
                this.tableDataMain[t].single_price_bj = XEUtils.reduce([this.tableDataMain[t].amount_of_product, this.tableDataMain[t].pounds_fees,
                    this.tableDataMain[t].wind_cut_fees, this.tableDataMain[t].loading_car_fees, this.tableDataMain[t].transfer_fees,
                    this.tableDataMain[t].freight_fees, this.tableDataMain[t].other_freight_fees,10], (previous, item) => this.$XEUtils.add(previous,item));
                console.log(this.tableDataMain[t].single_price_bj);
                if (!this.isBlankVue(this.tableDataMain[t].single_price_bj) && !this.isBlankVue(this.tableDataMain[t].single_count)){
                    this.tableDataMain[t].single_price_bj = this.$XEUtils.round(this.$XEUtils.divide(this.tableDataMain[t].single_price_bj, this.tableDataMain[t].single_count),2);
                }else{
                    this.tableDataMain[t].single_price_bj = '';
                }
            }
        },*/
        //计算每行 实际销售金额
        $_setActualSalesAmountBill(){
            let actualSalesAmountBill = Number(0);
            for (var t in this.tableDataMain) {
                if (this.tableDataMain[t].actual_sales_amount != null) {
                    actualSalesAmountBill = this.$XEUtils.add(actualSalesAmountBill, Number(this.tableDataMain[t].actual_sales_amount));
                }
            }
            this.formData.actual_sales_amount_bill = actualSalesAmountBill;

        },
        //计算每行外省运费裸价 = （行）外省运费 ÷ (1 +外省运费税率)
        //other_freight_fees_tax       外省运费税率
        //other_freight_fees_taxMoney  外省运费税金
        //nakedOtherFreightFeesTaxMoney  (不含税)外省运费裸价
        //otherFreightFees          外省运费
        $_getNakedOtherFreightFeesTaxMoneyEveryRow(){
            for (var t in this.tableDataMain) {
                if (!this.isBlankVue(this.tableDataMain[t].other_freight_fees)) {
                    let tax =this.$XEUtils.add(this.$XEUtils.divide(this.formData.other_freight_fees_tax,100),1);
                    //每行外省运费裸价
                    this.tableDataMain[t].naked_other_freight_fees_tax_money = this.$XEUtils.round(this.$XEUtils.divide(this.tableDataMain[t].other_freight_fees,tax),2);// 外省运费裸价，不含税金额
                    //每行外省运费税金
                    this.tableDataMain[t].other_freight_fees_tax_money = this.$XEUtils.round(this.$XEUtils.subtract(this.tableDataMain[t].other_freight_fees,this.tableDataMain[t].naked_other_freight_fees_tax_money),2);
                }else{
                    this.tableDataMain[t].naked_other_freight_fees_tax_money = '';
                    this.tableDataMain[t].other_freight_fees_tax_money = '';
                }
            }
        },
        //合计每行中的杂费，由表格数据合计至头部
        $_sumFeesEveryRow() {
            let poundsFees = 0;
            let windCutFees = 0;
            let loadingCarFees = 0;
            let transferFees = 0;
            let freightFees = 0;
            let otherFreightFees = 0;
            let procedureFees = 0;
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (!this.isBlankVue(this.tableDataMain[t].pounds_fees) && this.formData.pounds_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    poundsFees = this.$XEUtils.add(poundsFees, this.tableDataMain[t].pounds_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].wind_cut_fees) && this.formData.wind_cut_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    windCutFees = this.$XEUtils.add(windCutFees, this.tableDataMain[t].wind_cut_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].loading_car_fees) && this.formData.loading_car_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    loadingCarFees = this.$XEUtils.add(loadingCarFees, this.tableDataMain[t].loading_car_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].transfer_fees) && this.formData.transfer_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    transferFees = this.$XEUtils.add(transferFees, this.tableDataMain[t].transfer_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].freight_fees) && this.formData.freight_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    freightFees = this.$XEUtils.add(freightFees, this.tableDataMain[t].freight_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].other_freight_fees) && this.formData.other_freight_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    otherFreightFees = this.$XEUtils.add(otherFreightFees, this.tableDataMain[t].other_freight_fees);
                }
                if (!this.isBlankVue(this.tableDataMain[t].procedure_fees) && this.formData.procedure_fees_diy) {
                    //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                    procedureFees = this.$XEUtils.add(procedureFees, this.tableDataMain[t].procedure_fees);
                }

            }
            if (this.formData.pounds_fees_diy) {
                this.formData.pounds_fees = this.$XEUtils.round(poundsFees,2);
            }
            if (this.formData.wind_cut_fees_diy) {
                this.formData.wind_cut_fees = this.$XEUtils.round(windCutFees,2);
            }
            if (this.formData.loading_car_fees_diy) {
                this.formData.loading_car_fees = this.$XEUtils.round(loadingCarFees,2);
            }
            if (this.formData.transfer_fees_diy) {
                this.formData.transfer_fees = this.$XEUtils.round(transferFees,2);
            }
            if (this.formData.freight_fees_diy) {
                this.formData.freight_fees = this.$XEUtils.round(freightFees,2);
            }
            if (this.formData.other_freight_fees_diy) {
                this.formData.other_freight_fees = this.$XEUtils.round(otherFreightFees,2);
            }
            if (this.formData.procedure_fees_diy) {
                this.formData.procedure_fees = this.$XEUtils.round(procedureFees,2);
            }
        },
        //设置外省运费裸价
        //other_freight_fees_tax       外省运费税率
        //other_freight_fees_taxMoney  外省运费税金
        //otherFreightFees          外省运费
        $_setNakedOtherFreightFeesTaxMoney(){
            if(!this.isBlankVue(this.formData.other_freight_fees_tax) && !this.isBlankVue(this.formData.other_freight_fees)){
                let tax =this.$XEUtils.add(this.$XEUtils.divide(this.formData.other_freight_fees_tax,100),1);
                this.formData.naked_other_freight_fees_tax_money = this.$XEUtils.round(this.$XEUtils.divide(this.formData.other_freight_fees,tax),2);// 外省运费裸价，不含税金额
                this.formData.other_freight_fees_tax_money = this.$XEUtils.round(this.$XEUtils.multiply(this.formData.naked_other_freight_fees_tax_money,this.$XEUtils.divide(this.formData.other_freight_fees_tax,100)),2);
            }else{
                this.formData.other_freight_fees_tax_money = 0;
                this.formData.naked_other_freight_fees_tax_money = this.formData.other_freight_fees;
            }
        },
        //计算每一行 磅费
        $_getFeesEveryRow() {
            let poundsFees = 0;//每kg过磅费
            let windCutFees = 0;//每kg风割费
            let loadingCarFees = 0;
            let transferFees = 0;
            let freightFees = 0;
            let otherFreightFees = 0;
            let procedureFees = 0;

            let poundsFeesTotal = 0;//过磅费合计，用于行内四舍五入合计
            let windCutFeesTotal = 0;//风割费合计，用于行内四舍五入合计
            let loadingCarFeesTotal = 0;
            let transferFeesTotal = 0;
            let freightFeesTotal = 0;
            let otherFreightFeesTotal = 0;
            let procedureFeesTotal = 0;
            //let taxesFeesSecondString = 0;
            /*console.log('this.formData.total_weighing_heavy', this.formData.total_weighing_heavy);
            console.log('this.formData.transferFees', this.formData.transfer_fees);
            console.log('this.formData.transfer_fees_diy', this.formData.transfer_fees_diy);*/
            if (!this.isBlankVue(this.formData.total_weighing_heavy)) {
                if (!this.isBlankVue(this.formData.pounds_fees)) {
                    poundsFees = this.$XEUtils.divide(this.formData.pounds_fees, this.formData.total_weighing_heavy);
                }
                if (!this.isBlankVue(this.formData.wind_cut_fees)) {
                    windCutFees = this.$XEUtils.divide(this.formData.wind_cut_fees, this.formData.total_weighing_heavy);
                }
                if (!this.isBlankVue(this.formData.loading_car_fees)) {
                    loadingCarFees = this.$XEUtils.divide(this.formData.loading_car_fees, this.formData.total_weighing_heavy);
                }
                //中转费
                if (!this.isBlankVue(this.formData.transfer_fees) && !this.formData.transfer_fees_diy) {
                    transferFees = this.$XEUtils.divide(this.formData.transfer_fees, this.formData.total_weighing_heavy);
                    //console.log('this.formData.total_weighing_heavy', this.formData.total_weighing_heavy);
                }
                if (!this.isBlankVue(this.formData.freight_fees)) {
                    //console.log('this.formData.total_weighing_heavy', this.formData.total_weighing_heavy);
                    freightFees = this.$XEUtils.divide(this.formData.freight_fees, this.formData.total_weighing_heavy);
                }
                if (!this.isBlankVue(this.formData.other_freight_fees)) {
                    //console.log('this.formData.total_weighing_heavy', this.formData.total_weighing_heavy);
                    otherFreightFees = this.$XEUtils.divide(this.formData.other_freight_fees, this.formData.total_weighing_heavy);
                }
                if (!this.isBlankVue(this.formData.procedure_fees)) {
                    //console.log('this.formData.total_weighing_heavy', this.formData.total_weighing_heavy);
                    procedureFees = this.$XEUtils.divide(this.formData.procedure_fees, this.formData.total_weighing_heavy);
                }
                /* if (!this.isBlankVue(this.formData.taxes_fees_second_string) ){
                     taxesFeesSecondString = this.$XEUtils.divide(this.formData.taxes_fees_second_string,this.formData.total_weighing_heavy);
                 }*/
                //singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                //singleCountAll = this.$XEUtils.add(singleCountAll, this.tableDataMain[t].single_count);
            }

            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (!this.isBlankVue(this.tableDataMain[t].weighing_heavy)) {
                    if (!this.formData.pounds_fees_diy) {
                        this.tableDataMain[t].pounds_fees = this.$XEUtils.round(this.$XEUtils.multiply(poundsFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    poundsFeesTotal = this.$XEUtils.add(poundsFeesTotal,this.tableDataMain[t].pounds_fees);
                    if (!this.formData.wind_cut_fees_diy) {
                        this.tableDataMain[t].wind_cut_fees = this.$XEUtils.round(this.$XEUtils.multiply(windCutFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    windCutFeesTotal = this.$XEUtils.add(windCutFeesTotal,this.tableDataMain[t].wind_cut_fees);
                    if (!this.formData.loading_car_fees_diy) {
                        this.tableDataMain[t].loading_car_fees = this.$XEUtils.round(this.$XEUtils.multiply(loadingCarFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    loadingCarFeesTotal = this.$XEUtils.add(loadingCarFeesTotal,this.tableDataMain[t].loading_car_fees);
                    if (!this.formData.transfer_fees_diy) {
                        this.tableDataMain[t].transfer_fees = this.$XEUtils.round(this.$XEUtils.multiply(transferFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    transferFeesTotal = this.$XEUtils.add(transferFeesTotal,this.tableDataMain[t].transfer_fees);
                    if (!this.formData.freight_fees_diy) {
                        this.tableDataMain[t].freight_fees = this.$XEUtils.round(this.$XEUtils.multiply(freightFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    freightFeesTotal = this.$XEUtils.add(freightFeesTotal,this.tableDataMain[t].freight_fees);
                    if (!this.formData.other_freight_fees_diy) {
                        this.tableDataMain[t].other_freight_fees = this.$XEUtils.round(this.$XEUtils.multiply(otherFreightFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    otherFreightFeesTotal = this.$XEUtils.add(otherFreightFeesTotal,this.tableDataMain[t].other_freight_fees);
                    if (!this.formData.procedure_fees_diy) {
                        this.tableDataMain[t].procedure_fees = this.$XEUtils.round(this.$XEUtils.multiply(procedureFees, this.tableDataMain[t].weighing_heavy), 2);
                    }
                    procedureFeesTotal = this.$XEUtils.add(procedureFeesTotal,this.tableDataMain[t].procedure_fees);
                    //this.tableDataMain[t].taxes = this.$XEUtils.round(this.$XEUtils.multiply(taxesFeesSecondString,this.tableDataMain[t].weighing_heavy),4);
                } else {
                    if (!this.formData.pounds_fees_diy) {
                        this.tableDataMain[t].pounds_fees = '';
                    }
                    if (!this.formData.wind_cut_fees_diy) {
                        this.tableDataMain[t].wind_cut_fees = '';
                    }
                    if (!this.formData.loading_car_fees_diy) {
                        this.tableDataMain[t].loading_car_fees = '';
                    }
                    if (!this.formData.transfer_fees_diy) {
                        this.tableDataMain[t].transfer_fees = '';
                    }
                    if (!this.formData.freight_fees_diy) {
                        this.tableDataMain[t].freight_fees = '';
                    }
                    if (!this.formData.other_freight_fees_diy) {
                        this.tableDataMain[t].other_freight_fees = '';
                    }
                    if (!this.formData.procedure_fees_diy) {
                        this.tableDataMain[t].procedure_fees = '';
                    }
                    //this.tableDataMain[t].taxes = '';
                }
            }
            //判断是否相等，如果不相等则自动调整最后一个规格的金额
            if (this.formData.pounds_fees != poundsFeesTotal && !this.formData.pounds_fees_diy){
                //console.log('poundsFees formData',this.formData.pounds_fees)
                //console.log('poundsFeesTotal',poundsFeesTotal)
                //console.log('poundsFees subtract',this.$XEUtils.subtract(this.formData.pounds_fees,poundsFeesTotal))
                let val = this.$XEUtils.subtract(this.formData.pounds_fees,poundsFeesTotal);
                //console.log('修改前：',this.tableDataMain[0].pounds_fees);
                //console.log('修改后：',this.$XEUtils.add(this.tableDataMain[0].pounds_fees,val));
                this.tableDataMain[0].pounds_fees = this.$XEUtils.add(this.tableDataMain[0].pounds_fees,val);
            }
            if (this.formData.wind_cut_fees != windCutFeesTotal && !this.formData.wind_cut_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.wind_cut_fees,windCutFeesTotal);
                this.tableDataMain[0].wind_cut_fees = this.$XEUtils.add(this.tableDataMain[0].wind_cut_fees,val);
                //console.log('windCutFees',this.$XEUtils.subtract(this.formData.wind_cut_fees,windCutFeesTotal))
            }
            if (this.formData.loading_car_fees != loadingCarFeesTotal && !this.formData.loading_car_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.loading_car_fees,loadingCarFeesTotal);
                this.tableDataMain[0].loading_car_fees = this.$XEUtils.add(this.tableDataMain[0].loading_car_fees,val);
                //console.log('loadingCarFees',this.$XEUtils.subtract(this.formData.loading_car_fees,loadingCarFeesTotal))
            }
            if (this.formData.transfer_fees != transferFeesTotal && !this.formData.transfer_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.transfer_fees,transferFeesTotal);
                this.tableDataMain[0].transfer_fees = this.$XEUtils.add(this.tableDataMain[0].transfer_fees,val);
                //console.log('transferFees',this.$XEUtils.subtract(this.formData.transfer_fees,transferFeesTotal))
            }
            if (this.formData.freight_fees != freightFeesTotal && !this.formData.freight_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.freight_fees,freightFeesTotal);
                this.tableDataMain[0].freight_fees = this.$XEUtils.add(this.tableDataMain[0].freight_fees,val);
                //console.log('freightFees',this.$XEUtils.subtract(this.formData.freight_fees,freightFeesTotal))
            }
            if (this.formData.other_freight_fees != otherFreightFeesTotal && !this.formData.other_freight_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.other_freight_fees,otherFreightFeesTotal);
                this.tableDataMain[0].other_freight_fees = this.$XEUtils.add(this.tableDataMain[0].other_freight_fees,val);
                //console.log('otherFreightFees',this.$XEUtils.subtract(this.formData.other_freight_fees,otherFreightFeesTotal))
            }
            if (this.formData.procedure_fees != procedureFeesTotal && !this.formData.procedure_fees_diy){
                let val = this.$XEUtils.subtract(this.formData.procedure_fees,procedureFeesTotal);
                this.tableDataMain[0].procedure_fees = this.$XEUtils.add(this.tableDataMain[0].procedure_fees,val);
                //console.log('procedureFees',this.$XEUtils.subtract(this.formData.procedure_fees,procedureFeesTotal))
            }
        },


        //计算行 佣金
        $_getKickBack(row) {
            row.kick_back = '';
            if (!this.isBlankVue(row.amount_of_product) && !this.isBlankVue(row.practical_price)) {
                //row.kick_back = Number(this.NumberSub(Number(row.amount_of_product), Number(row.practical_price))).toFixed(2);
                row.kick_back = this.$XEUtils.round(this.$XEUtils.subtract(row.amount_of_product, row.practical_price), 5);
            }
        },
        //计算行实际金额
        $_getPracticalPriceEvent(row) {
            row.practical_price = Number(0);
            if (row.practical_price != null && row.single_count != null) {
                if (!this.isBlankVue(row.single_price_practical) && !this.isBlankVue(row.single_count)) {
                    if (row.unit == '支') {
                        //row.practical_price = this.NumberMul(row.single_price_practical, row.single_count).toFixed(2);
                        row.practical_price = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price_practical, row.single_count), 2);
                    } else if (row.unit == '吨') {
                        //row.practical_price = this.NumberMul(row.single_price_practical, row.weighing_heavy).toFixed(2);
                        row.practical_price = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price_practical, row.weighing_heavy), 2);
                    }
                } else {
                    row.practical_price = '';
                }
            }
        },
        //计算行 理论重量
        $_getTheoreticalWeightSingle(row) {
            //console.log(`${column.title} 触发 change 事件`)
            //console.log(`getTheTheoryOfCountEvent 触发 change 事件` + row.specification + "   :  " + row.single_count)
            //初始化
            row.theoretical_weight = '';
            row.theoretical_weight_single = '';
            if (row.specification != null && row.specification.split("*").length >= 4) {
                row.theoretical_weight = Number(0);
                row.theoretical_weight_single = Number(0);
                const s = row.specification.split("*")
                const sideA = Number(s[0]);
                const sideB = Number(s[1]);
                const wallThickness = Number(s[2]);
                //const lengthExtent = Number(s[3].slice(0, s[3].indexOf("米") + 1).replace("米", ""));
                const lengthExtent = Number(s[3].slice(0, s[3].indexOf("米") + 1).replace("米", ""));
                // const theoretical_weight = (((sideA + sideB) * 2 - 5 * wallThickness) * wallThickness * 0.00785 * lengthExtent) / 1000;
                const ab = this.$XEUtils.add(sideA, sideB);//(sideA + sideB)
                const abTwo = this.$XEUtils.multiply(ab, 2);//(sideA + sideB) * 2
                const wallThicknessMul = this.$XEUtils.multiply(4, wallThickness);//5 * wallThickness
                const wallThicknessSub = this.$XEUtils.subtract(abTwo, wallThicknessMul);//((sideA + sideB) * 2 - 5 * wallThickness)
                const wallThicknessMulAll = this.$XEUtils.multiply(wallThicknessSub, wallThickness);//((sideA + sideB) * 2 - 5 * wallThickness) * wallThickness
                const wallThicknessMulSome = this.$XEUtils.multiply(wallThicknessMulAll, 0.00785);// * 0.00785
                const wallThicknessMulLengthExtent = this.$XEUtils.multiply(wallThicknessMulSome, lengthExtent);//* lengthExtent
                const theoretical_weight = this.$XEUtils.divide(wallThicknessMulLengthExtent, 1000);//  * 1000
                row.theoretical_weight_single = this.$XEUtils.round(theoretical_weight, 5);
                //理论重量 = 理论支重 * 数量
                if (!this.isBlankVue(row.single_count)) {
                    //row.theoretical_weight = (theoretical_weight * Number(row.single_count)).toFixed(6);
                    row.theoretical_weight = this.$XEUtils.round(this.$XEUtils.multiply(theoretical_weight, row.single_count), 5);
                }
                //row.unit == '支' &&
                if (!this.isBlankVue(row.single_count) && this.isBlankVue(row.weighing_heavy)) {
                    //过磅重
                    row.weighing_heavy = row.theoretical_weight
                }

            }
            //console.log(row)
            //console.log(column)
        },
        //计算行产品金额
        $_getAmountOfProductEvent(row) {
            //console.log(`getAmountOfProductEvent触发 change 事件`)
            row.amount_of_product = Number(0);
            if (row.unit != null && ((row.tons_of_price != null && row.weighing_heavy != null) || (row.single_price != null && row.single_count != null))) {
                if (row.unit == '吨' && row.tons_of_price != null && row.weighing_heavy != null) {
                    row.amount_of_product = this.$XEUtils.round(this.$XEUtils.multiply(row.tons_of_price, row.weighing_heavy), 2);
                } else if (row.unit == '支' && row.single_price != null && row.single_count != null) {
                    //row.amount_of_product = Number(row.single_price * row.single_count).toFixed(2);
                    //row.amount_of_product = this.NumberMul(row.single_price, row.single_count).toFixed(2);
                    row.amount_of_product = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price, row.single_count), 2);
                } else {
                    row.amount_of_product = '';
                }
            }
        },
        //计算行理论支数
        $_getTheTheoryOfCount(row) {
            if (this.isNumberVue(row.weighing_heavy) && this.isNumberVue(row.theoretical_weight_single)) {
                row.the_theory_of_count = this.$XEUtils.round(this.$XEUtils.divide(row.weighing_heavy, row.theoretical_weight_single), 2);
                //row.the_theory_of_count = Number(Number(row.weighing_heavy) / Number(row.theoretical_weight)).toFixed(1)
                if (isNaN(row.the_theory_of_count )) {
                    row.the_theory_of_count = null;
                }
            } else {
                row.the_theory_of_count = null;
            }
        },
        //计算行 支重
        $_getSingleWeight(row) {
            row.single_weight = '';
            //console.log('this.isNumberVue(row.single_count)',this.isNumberVue(row.single_count))
            //console.log('this.isNumberVue(row.weighing_heavy)',this.isNumberVue(row.weighing_heavy))
            if (this.isNumberVue(row.single_count) && this.isNumberVue(row.weighing_heavy)) {
                row.single_weight = this.$XEUtils.round(this.$XEUtils.divide(this.$XEUtils.multiply(row.weighing_heavy, 1000), row.single_count), 5);
            }
        },
        //单价 设置每支单价
        //销售开单 设置吨价
        $_setSinglePrice(row) {
            if (row.unit == '支') {
                //(单位:支) 每支单价=单价
                row.single_price = row.price
            } else if (row.unit == '吨') {
                row.single_price = ''
                //(单位:吨) 每支单价 = 产品金额 ÷ 支数
                if (!this.isBlankVue(row.amount_of_product) && !this.isBlankVue(row.single_count)) {
                    row.single_price = this.$XEUtils.round(this.$XEUtils.divide(row.amount_of_product, row.single_count), 2);
                }
            }else{
                row.single_price = ''
            }
        },
        $_setTonsOfPrice(row) {
            if (row.unit == '支') {
                row.tons_of_price = ''
                //(单位:支)吨价 = 产品金额 ÷ 过磅重
                if (!this.isBlankVue(row.weighing_heavy) && !this.isBlankVue(row.amount_of_product)) {
                    row.tons_of_price = this.$XEUtils.round(Number(this.$XEUtils.divide(row.amount_of_product, row.weighing_heavy)), 2);
                }
            } else if (row.unit == '吨') {
                //(单位:吨)吨价 = 单价
                row.tons_of_price = row.price
            }
        },
        //销售开单 实际吨价 TonsOfPricePractical
        $_setTonsOfPricePractical(row) {
            if (!this.isBlankVue(row.weighing_heavy)) {
                row.tons_of_price_practical = this.$XEUtils.round(Number(this.$XEUtils.divide(row.naked_price , row.weighing_heavy)), 2);
            } else {
                row.tons_of_price_practical = '';
            }
        },
        //销售开单 实际销售支价
        $_setActualAPrice(row) {
            if (!this.isBlankVue(row.single_count)) {
                row.actual_a_price = this.$XEUtils.round(Number(this.$XEUtils.divide(row.actual_sales_amount , row.single_count)), 2);
            } else {
                row.actual_a_price = '';
            }
        },
        //销售开单 实际销售支价(不含税)
        $_setActualAPriceNoTax(row) {
            if (!this.isBlankVue(row.single_count)) {
                row.actual_a_price_no_tax = this.$XEUtils.round(Number(this.$XEUtils.divide(row.naked_price , row.single_count)), 2);
            } else {
                row.actual_a_price_no_tax = '';
            }
        },
        //销售开单 实际销售吨价
        $_setActualTonPrice(row) {
            if (!this.isBlankVue(row.weighing_heavy)) {
                row.actual_ton_price = this.$XEUtils.round(Number(this.$XEUtils.divide(row.actual_sales_amount , row.weighing_heavy)), 2);
            } else {
                row.actual_ton_price = '';
            }
        },
        //实收单价
        $_setSinglePricePractical(row) {
            //如果不是开单，实收单价永远等于单价
            if (this.isBlankVue(row.single_price_practical)) {
                row.single_price_practical = row.price
            }
            if (this.formData.bill_id_type == 'JC') {
                row.single_price_practical = row.price
            }
            /*if (!this.isBlankVue(row.price)) {
                row.single_price_practical = row.price
            } else if (this.isBlankVue(row.price)) {
                row.single_price_practical = ''
            }*/
        },
        //杂费税金
        $_setOtherTaxMoney() {
            //合计杂费为空，或则，杂费税率为空，则杂费税金为空
            if (this.isBlankVue(this.formData.all_fees) || this.isBlankVue(this.formData.other_tax)) {
                this.formData.other_tax_money = '';
            } else {
                let otherTax2 = this.$XEUtils.divide(this.formData.other_tax, 100);
                this.formData.other_tax_money = this.$XEUtils.round(this.$XEUtils.multiply(this.$XEUtils.divide(this.formData.all_fees , this.$XEUtils.add(1, otherTax2)), otherTax2), 2)
            }

        },
        //销售开单 产品税金 = 产品裸价 * 税率
        $_setTaxes(row) {
            //税率为空，或则，产品金额为空，则产品税金为空
            if (this.isBlankVue(row.naked_price ) || this.isBlankVue(row.tax_tate_stock)) {
                row.taxes_fees = '';
            } else {
                row.taxes_fees = this.$XEUtils.round(Number(this.$XEUtils.multiply(row.naked_price , this.$XEUtils.divide(row.tax_tate_stock, 100))), 2)
            }
        },
        //销售开单 产品裸价 = 实收金额
        $_setNakedPrice(row) {
            //如果是进仓单，产品裸价默认 =  产品金额
            //如果是销售开单，产品裸价默认 =  实收金额(产品金额 - 佣金)
            //则直接，产品裸价默认 = 产品金额 - 佣金 为了让进仓单通用
            let subValue = row.kick_back;
            if(this.isBlankVue(row.kick_back) ){
                //为了进仓单设置一个默认值，小于0
                subValue = 0;
            }
            row.naked_price = this.$XEUtils.subtract(row.amount_of_product,subValue);

            //包费用，那就需要减去费用
            if (this.formData.is_contract_fees == 1) {
                //console.log('产品裸价不，由包不包费用来决定');
                //产品裸价不，由包不包费用来决定
                //row.naked_price = this.$XEUtils.subtract(row.naked_price ,row.taxes);
                row.naked_price = this.$XEUtils.subtract(row.naked_price , row.pounds_fees);
                row.naked_price = this.$XEUtils.subtract(row.naked_price , row.wind_cut_fees);
                row.naked_price = this.$XEUtils.subtract(row.naked_price , row.loading_car_fees);
                row.naked_price = this.$XEUtils.subtract(row.naked_price , row.transfer_fees);
                row.naked_price = this.$XEUtils.subtract(row.naked_price , row.procedure_fees);
                row.naked_price = this.$XEUtils.round(this.$XEUtils.subtract(row.naked_price , row.freight_fees),2);
            }
            if (row.naked_price == 0) {
                row.naked_price = ''
            } else if (!this.isBlankVue(row.tax_tate_stock)) {
                let tax = this.$XEUtils.add(1, this.$XEUtils.divide(row.tax_tate_stock, 100));
                //console.log('tax',tax);
                row.naked_price = this.$XEUtils.round(this.$XEUtils.divide(row.naked_price ,tax), 2);
            }else{
                row.naked_price = this.$XEUtils.round(row.naked_price , 2);
            }
        },
        //销售开单 实际销售金额
        $_setActualSalesAmount(row) {
            let subValue = row.kick_back;
            if(this.isBlankVue(row.kick_back) ){
                subValue = 0;
            }
            row.actual_sales_amount = this.$XEUtils.subtract(row.amount_of_product,subValue);
            //包费用，那就需要减去费用
            if (this.formData.is_contract_fees == 1) {
                //console.log('产品裸价不，由包不包费用来决定');
                //产品裸价不，由包不包费用来决定
                row.actual_sales_amount = this.$XEUtils.subtract(row.actual_sales_amount, row.pounds_fees);
                row.actual_sales_amount = this.$XEUtils.subtract(row.actual_sales_amount, row.wind_cut_fees);
                row.actual_sales_amount = this.$XEUtils.subtract(row.actual_sales_amount, row.loading_car_fees);
                row.actual_sales_amount = this.$XEUtils.subtract(row.actual_sales_amount, row.transfer_fees);
                row.actual_sales_amount = this.$XEUtils.subtract(row.actual_sales_amount, row.procedure_fees);
                row.actual_sales_amount = this.$XEUtils.round(this.$XEUtils.subtract(row.actual_sales_amount, row.freight_fees),2);
            }
            if (row.actual_sales_amount == 0) {
                row.actual_sales_amount = ''
            }else{
                row.actual_sales_amount = this.$XEUtils.round(row.actual_sales_amount, 2);
            }
        },
        //销售开单 实际销售单价
        $_setActualSinglePrice(row){
            if (row.unit == '支') {
                row.actual_single_price = ''
                if (!this.isBlankVue(row.actual_sales_amount) && !this.isBlankVue(row.single_count)) {
                    row.actual_single_price = this.$XEUtils.round(this.$XEUtils.divide(row.actual_sales_amount, row.single_count), 2);
                }
            } else if (row.unit == '吨') {
                row.actual_single_price = ''
                //单价 = 产品金额 ÷ 过磅重
                if (!this.isBlankVue(row.actual_sales_amount) && !this.isBlankVue(row.weighing_heavy)) {
                    row.actual_single_price = this.$XEUtils.round(this.$XEUtils.divide(row.actual_sales_amount, row.weighing_heavy), 2);
                }
            }else{
                row.actual_single_price = ''
            }
        },
        //销售开单 税费
        $_setTableTaxes(tableDataMain) {
            for (let i = 0; i < tableDataMain.length; i++) {
                //判断产品金额不为空，才进行计算税费
                if (!this.isBlankVue(tableDataMain[i].amount_of_product)) {
                    this.$_setTaxes(tableDataMain[i]);
                    //顺便重新计算 实际吨价
                    this.$_setTonsOfPricePractical(tableDataMain[i]);
                    //this.$_setActualAPrice(tableDataMain[i]);
                    //this.$_setActualTonPrice(tableDataMain[i]);
                }

            }
            /*tableDataMain.forEach(item => {
                this.$_setTaxes({item},taxTateWarehouseBill);
                if (this.isBlankVue(item.practical_price) || this.isBlankVue(taxTateWarehouseBill)) {
                    item.taxes = '';
                } else {
                    item.taxes = Number(this.$XEUtils.multiply(item.practical_price, this.$XEUtils.divide(taxTateWarehouseBill, 100)));
                }
                //每行都计算一遍，特别是实际吨价
                //this.$_singleCountEvent(item);
            })*/
            //row.taxes = Number(this.$XEUtils.multiply(row.practical_price,this.$XEUtils.divide(this.formData.tax_tate_warehouse_bill,100))).toFixed(2)
        },


        //合计税金
        $_sumTaxesFees() {
            let sumTaxesFees = Number(0);
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (!this.isBlankVue(this.tableDataMain[t].taxes_fees)) {
                    sumTaxesFees = this.$XEUtils.add(sumTaxesFees, Number(this.tableDataMain[t].taxes_fees));
                    //console.log('sumTaxesFees',sumTaxesFees);
                }
            }
            this.formData.taxes_fees = this.$XEUtils.add(sumTaxesFees, this.formData.other_tax_money);
            this.formData.taxes_fees = this.$XEUtils.add(this.formData.taxes_fees, this.formData.other_freight_fees_tax_money);
            //判断是否存在“后补税金”
            if (!this.isBlankVue(this.formData.taxes_fees_second_string)) {
                this.formData.taxes_fees = this.formData.taxes_fees_second_string;
            }
            this.formData.taxes_fees = this.$XEUtils.round(this.formData.taxes_fees,2)
        },
        //进仓单、销售开单 合计金额
        $_sumTotalAmount() {
            let totalAmountSumAll = Number(0);

            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (!this.isBlankVue(this.tableDataMain[t].amount_of_product)) {
                    totalAmountSumAll = this.$XEUtils.add(totalAmountSumAll, Number(this.tableDataMain[t].amount_of_product));
                    //console.log('sumTaxesFees',sumTaxesFees);
                }
            }

            //transhipmentCharges 进仓单 每行中转费合计
            //totalAmountOfProduct 合计产品金额
            //additionalCharges 总费用（合计附加费）
            //判断是否包费用，不是包费用就需要加 addALlFees
            /*totalAmountSumAll = this.$XEUtils.add(Number(this.formData.transhipmentCharges), Number(this.formData.total_amount_of_product));
            totalAmountSumAll = this.$XEUtils.add(totalAmountSumAll,Number(this.formData.additionalCharges));
            */
            if (this.formData.bill_id_type == 'JC') {
                totalAmountSumAll = this.$XEUtils.add(totalAmountSumAll, this.formData.other_freight_fees);
            }else{
                if (this.formData.is_contract_fees == 0) {
                    totalAmountSumAll = this.$XEUtils.add(totalAmountSumAll, this.formData.all_fees);
                } else {
                    //包费用，实收需要减去 总费用
                    //由合计实收自己判断，去减合计费用
                }
            }
            //如果后补税金不为空，则把后补税金加上去
            if(!this.isBlankVue(this.formData.taxes_fees_second_string)){
                totalAmountSumAll = Number(totalAmountSumAll + Number(this.formData.taxes_fees_second_string));
            }

            this.formData.total_amount = this.$XEUtils.round(Number(totalAmountSumAll), 2);
        },
        //合计费用
        $_sumAllFees() {
            let addALlFees = 0;
            addALlFees = this.$XEUtils.add(this.formData.pounds_fees, this.formData.wind_cut_fees);
            let addALlFees2 = this.$XEUtils.add(this.formData.loading_car_fees, this.formData.transfer_fees);
            let addALlFees3 = this.$XEUtils.add(this.formData.freight_fees,0);//this.formData.other_freight_fees 不需要加外省运费
            addALlFees = this.$XEUtils.add(addALlFees, addALlFees2);
            addALlFees = this.$XEUtils.add(addALlFees, addALlFees3);
            addALlFees = this.$XEUtils.add(addALlFees, this.formData.procedure_fees);
            //console.log('addALlFees',addALlFees);
            this.formData.all_fees = this.$XEUtils.round(Number(addALlFees), 2);
            //this.formData.all_fees = this.$XEUtils.round(Number(this.XEUtils.reduce([this.formData.pounds_fees,this.formData.wind_cut_fees, this.formData.loading_car_fees,this.formData.transfer_fees, this.formData.freight_fees,this.formData.taxesFees], (previous, item) => previous + item)),2);
        },

        //进仓单 合计产品金额
        $_sumAmountOfProduct() {
            var amountOfProductAll = Number(0);
            for (var t in this.tableDataMain) {
                //console.log(this.tableDataMain[t].theoretical_weight);
                if (this.tableDataMain[t].amount_of_product != null) {
                    amountOfProductAll = Number(amountOfProductAll + Number(this.tableDataMain[t].amount_of_product));
                }
            }

            this.formData.total_amount_of_product = this.$XEUtils.round(amountOfProductAll, 2);
        },
        /**
         * 公式计算逻辑 end
         * @param row
         */


        $_createRowDataByReceipt(list,size) {
            if (size == null || size == ''){
                size = 30;
            }
            for (var index = list.length; index < size; index++) {
                list.push({
                    id: '',
                    cash_id: '',//对应出纳单据编号
                    summary_cash_data : '',//摘要
                    subject_id_cash_data: '',//科目ID
                    subject_name_cash_data : '',//科目名称
                    money_cash_data : '',//金额
                    check_number : '',//支票号码
                    check_date : '',//支票日期
                    draw_company : '',//出票单位
                    balance_money : '',//冲账金额
                    last_name_cash_data : '',//最后修改名
                    last_date_cash_data : '',//最后修改时间
                    delete_man: '',//删除人
                    creat_date_cash_data : '',//创建日期
                    registrar_cash_data : '',//记录人
                    bill_id_kd_or_jc : '',//开单、进仓ID
                    sales_man : '',//业务员
                    company_name : '',//公司名称
                    company_uuid : '',//公司UUID
                    fees_name : '',//对扣销售、进仓单费用名字
                    company_name_assist : '',
                    company_uuid_assist : '',
                    order_id : index + 1,
                    operateVisible : false,
                })
            }
            return list;
        },
        /**
         * 每个页面显示数据，创建带属性类名的对象
         * @param list
         * @returns {*}
         */
        $_createRowData(list,size) {
            if (size == null || size == ''){
                size = 30;
            }
            for (var index = list.length; index < size; index++) {
                list.push({
                    id: '',
                    order_id : index + 1,
                    texture: '',//材质
                    category: "",//类别
                    specification: "",//实际规格
                    unit: "",//单位
                    single_count_str : "",//支数(可文本)
                    single_weight_str: "",//支重(可文本)
                    single_count : "",//支数
                    single_count_temp : "",//支数_用于判断前后数据
                    price: "",//单价（吨）吨价（支）每支单价
                    tons_of_price : "",//吨价
                    tons_of_price_practical : "",//实际吨价
                    stock_bill_date_dd : "",//订单日期
                    remark_stock : "",//产品备注
                    warehouse: "",//调入仓库
                    warehouse_id : "",//调入仓库ID
                    warehouse_out : "",//调出仓库
                    warehouse_out_id : "",//调出仓库ID
                    total_count : "",//库存
                    warehouse4: "",//厂一
                    warehouse5: "",//厂二
                    warehouse1: "",//仓一
                    warehouse2: "",//仓二
                    warehouse3: "",//仓三
                    warehouse9: "",//仓四
                    warehouse7: "",//新门市
                    single_weight : "",//支重
                    theoretical_weight : "",//理论重量
                    theoretical_weight_single : "",//理论支重
                    single_price : "",//每支单价
                    weighing_heavy : "",//过磅重
                    amount_of_product : "",//产品金额
                    single_price_practical : "",//实收单价
                    practical_price : "",//实收金额
                    kick_back : "",//佣金
                    actual_thickness : "",//实厚
                    polish_roll : "",//打磨驳口
                    recently_price : "",//最近单价
                    registrar_last : "",//产品报价 每行中 上一次的报价的 记录员
                    client: "",//产品报价 每行中 上一次的报价的客户名称 (客户)
                    record_date : "",//产品报价 上一次报价的 记录日期
                    single_count_quotation : "",//产品报价 报价数量
                    taxes: "",//销售开单 税费
                    pounds_fees : "",//磅费
                    wind_cut_fees : "",//风割费
                    loading_car_fees : "",//装车费
                    transfer_fees : "",//中转费
                    freight_fees : "",//运费
                    procedure_fees : "",//手续费
                    other_freight_fees : "",//外省运费
                    naked_price : "",//产品裸价
                    taxes_fees : "",//产品税金
                    tons_of_price_bj : "",//产品税金
                    the_theory_of_count : "",//理论支数
                    other_freight_fees_tax_money : "",//外省运费 税金裸价（每行）
                    naked_into_warehouse_cost : "",//合计进仓成本
                    serial_number : "",//对应序号
                    cutting: "",//切割长度
                    wall_thickness : "",//厚度
                    theory_weight: "",//理计
                    full_thick: "",//足厚
                    tax_tate_stock: "",//产品税率
                    operateVisible : false,//是否显示操作弹层
                    dd_is_ok : 0,//销售开单 未完成0 完成1 部分完成2
                    dd_is_ok_count : '',//销售开单 完成支数
                    dd_state_name : '',//销售开单 完成支数
                    automatically : '',//是否自动生成存货成本金额
                });
            }
            return list;
        },
        /**
         * 日期格式化
         * @param value
         * @param format
         * @returns {*}
         */
        $_formatDate(value, format) {
            return XEUtils.toDateString(value, format)
        },
        /**
         * 数据为空则不显示
         */
        $_getDataByBlank(obj) {
            if (obj != null && obj != '' && obj != 0) {
                return obj;
            } else {
                return '';
            }
        },
        /**
         * 获取表格可以更新的记录
         * @returns {number}
         */
        $_isGridDataChange(xGrid) {
            if (!this.isBlankVue(xGrid)) {
                let changeRecords = xGrid.getRecordset()
                return changeRecords.updateRecords.length > 0 || changeRecords.removeRecords.length > 0 || changeRecords.insertRecords.length > 0
            }
        },
        /**
         * 数值金额转中文大写金额
         * @param {数值金额} money
         */
        $_digitUppercase(money){
            money = XEUtils.round(money, 2)
            var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
            var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
            var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
            var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
            var cnInteger = "整"; //整数金额时后面跟的字符
            var cnIntLast = "元"; //整型完以后的单位
            var maxNum = 999999999999999.9999; //最大处理的数字
            var IntegerNum; //金额整数部分
            var DecimalNum; //金额小数部分
            var ChineseStr = ""; //输出的中文金额字符串
            var parts; //分离金额后用的数组，预定义
            var Symbol="";//正负值标记
            if (money == "") {
                return "";
            }

            money = parseFloat(money);
            if (money >= maxNum) {
                alert('超出最大处理数字');
                return "";
            }
            if (money == 0) {
                ChineseStr = cnNums[0] + cnIntLast + cnInteger;
                return ChineseStr;
            }
            if(money<0)
            {
                money=-money;
                Symbol="负 ";
            }
            money = money.toString(); //转换为字符串
            if (money.indexOf(".") == -1) {
                IntegerNum = money;
                DecimalNum = '';
            } else {
                parts = money.split(".");
                IntegerNum = parts[0];
                DecimalNum = parts[1].substr(0, 4);
            }
            if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
                var zeroCount = 0;
                var IntLen = IntegerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = IntegerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == "0") {
                        zeroCount++;
                    }
                    else {
                        if (zeroCount > 0) {
                            ChineseStr += cnNums[0];
                        }
                        zeroCount = 0; //归零
                        ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        ChineseStr += cnIntUnits[q];
                    }
                }
                ChineseStr += cnIntLast;
                //整型部分处理完毕
            }
            if (DecimalNum != '') { //小数部分
                var decLen = DecimalNum.length;
                // eslint-disable-next-line no-redeclare
                for (var i = 0; i < decLen; i++) {
                    // eslint-disable-next-line no-redeclare
                    var n = DecimalNum.substr(i, 1);
                    if (n != '0') {
                        ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (ChineseStr == '') {
                ChineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (DecimalNum == '') {
                ChineseStr += cnInteger;
            }
            ChineseStr = Symbol +ChineseStr;

            return ChineseStr;
        },
        //每次刷新，重新读取用户权限
        $_findRoleVersion() {
            this.$axios({
                method: 'post',
                url: '/admin/roleVersion',
                data: {
                    roleVersion: this.$store.getters.getRoleVersion
                    //roleVersion: window.sessionStorage.getItem("roleVersion")
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    //&& window.sessionStorage.getItem("roleVersion") != null
                    if (response.data.roleVersion != null && response.data.account != null) {
                        //window.sessionStorage.setItem("roleVersion", response.data.roleVersion);
                        this.$store.dispatch('asyncUpdateRoleVersion', response.data.roleVersion);
                        this.$store.dispatch('asyncUpdateLoginAccount', response.data.account);
                    }
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //判断规格是否发生了最新的变化，如果是的话，更新最新的本地缓存
        $_isChangeSpecification(){
            this.$axios({
                method: 'post',
                url: '/admin/roleVersion/isChangeSpecification',
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status == 200 && response.data.state == "ok") {
                    //&& window.sessionStorage.getItem("roleVersion") != null
                    //console.log('this.isChangeSpecification',this.isChangeSpecification)
                    //console.log('response.data.isChangeSpecification',response.data.isChangeSpecification)
                    if (response.data.isChangeSpecification != this.isChangeSpecification) {
                        this.$_getSpecificationALL();
                        this.$_getSpecificationBJ();
                        this.$_getSpecificationHC();
                        this.isChangeSpecification = response.data.isChangeSpecification
                        //console.log('修改 $_isChangeSpecification')
                        //window.sessionStorage.setItem("roleVersion", response.data.roleVersion);
                        /*
                        this.$store.dispatch('asyncUpdateRoleVersion', response.data.roleVersion);
                        this.$store.dispatch('asyncUpdateLoginAccount', response.data.account);*/

                    }
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_getSpecificationALL(){
            this.$axios({
                method: 'post',
                url: '/admin/roleVersion/getSpecificationALL',
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status == 200 && response.data.state == "ok") {
                        this.$store.dispatch('asyncUpdateSpecificationList', response.data.list);
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_getSpecificationHC(){
            this.$axios({
                method: 'post',
                url: '/admin/roleVersion/getSpecificationHC',
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status == 200 && response.data.state == "ok") {
                    this.$store.dispatch('asyncUpdateSpecificationListHC', response.data.list);
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_getSpecificationBJ(){
            this.$axios({
                method: 'post',
                url: '/admin/roleVersion/getSpecificationBJ',
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status == 200 && response.data.state == "ok") {
                    //console.log('$_getSpecificationBJ',response.data.list)
                    this.$store.dispatch('asyncUpdateSpecificationListBJ', response.data.list);
                    //console.log('BJ',this.$store.getters.getSpecificationListBJ)
                }
            }).catch((error) => {
                console.log(error)
            });
        },

        //每次选择，重新读取税率
        $_batchNumberGetSupperTax(taxName,row,selectRow) {
            this.$axios({
                method: 'post',
                url: '/admin/taxRate/batchNumberGetSupperTax',
                data: {
                    taxName: taxName,
                    batchNumber: row.batch_number,
                }
            }).then((response) => {         //这里使用了ES6的语法
                if (response.status === 200) {
                    if(!this.isBlankVue(this.formData.tax_tate_warehouse_bill)){
                        if (!this.isBlankVue(row.tax_tate_stock) && this.isBlankVue(response.data.rate)){
                            //不变

                        }else if(!this.isBlankVue(selectRow.tax_tate_stock)){
                            row.tax_tate_stock = selectRow.tax_tate_stock
                        }else{
                            row.tax_tate_stock = response.data.rate
                        }
                    }
                    this.$_checkTaxTateStockRow();
                    //$_checkTaxTateStockRow(),$_taxTateWarehouseBillEvent(),$_singleCountEventEvery(),isDataChanage = true
                    this.$_taxTateWarehouseBillEvent();
                    this.$_singleCountEventEvery();
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        /*$_specificationSearch(e){
                    //this.$_searchByTotalSingleCount(query, '', true)
            /!*setTimeout(() => {
               // this.$refs.draw_company.selectedLabel = row.draw_company;
            }, 500)*!/
            console.log('$_specificationSearch enter',e)
            //this.$_searchByTotalSingleCount(e.target.value,'',true)
        },*/
        $_test(){
            console.log('test');
        },



        //出纳 出票单位
        $_searchCustomerAndSupplier(query) {
            //console.log('query',query);
            this.$axios({
                method: 'POST',
                url: '/admin/common/searchCustomerAndSupplier',
                data: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.$store.dispatch('asyncUpdateCustomerAndSupplierList',response.data);
                    //this.$data._CustomerAndSupplierList = response.data
                }
            }).catch((error) => {
                console.log(error)
            });
        },


        $_searchWarehouseStockLocal(query,searchType) {
            //console.log('$_searchWarehouseStockLocal 本地缓存');

            // 预处理关键词（Java逻辑转译）
            let keyword = query.trim();
            if (!this.isBlankVue(keyword)) {
                // 获取规格列表（假设这是完整数据）
                let fullList = [];
                /*console.log('searchType',searchType)
                console.log('this.$store.getters.getSpecificationListBJ',this.$store.getters.getSpecificationListBJ)
                console.log('this.$store.getters.getSpecificationListHC',this.$store.getters.getSpecificationListHC)
                console.log('this.$store.getters.getSpecificationList',this.$store.getters.getSpecificationList)*/
                if('ProductQuotation' == searchType ){
                    fullList = this.$store.getters.getSpecificationListBJ;
                }else if('ReplaceWarehouseBill' == searchType){
                    fullList = this.$store.getters.getSpecificationListHC;
                }else{
                    fullList = this.$store.getters.getSpecificationList;
                }
                // 步骤1：筛选包含关键词的所有条目
                // 新增关键词拆分
                const keywords = keyword.split(/\s+/).filter(k => k);

                // 修改筛选逻辑
                const matchedList = fullList.filter(item => {
                    return keywords.every(k =>
                        item.specification_name.includes(k) ||
                        item.category_name.includes(k)
                    )
                });



                // 第二步 - 对匹配结果进行多维度排序
                this.specificationDropDownTableData = matchedList.sort((a, b) => {
                    let comparison = 0;
                    // 第三优先级：方管/矩管优先（新增）
                    const aIsSpecial = ['方管','矩管'].includes(a.category_name);
                    const bIsSpecial = ['方管','矩管'].includes(b.category_name);
                    if (aIsSpecial !== bIsSpecial) {
                        return aIsSpecial ? -1 : 1; // 特殊材质排前
                    }

                    // 第一优先级：是否以关键词开头
                    const aStartsWith = a.specification_name.startsWith(keyword) ? 0 : 1;
                    const bStartsWith = b.specification_name.startsWith(keyword) ? 0 : 1;
                    if (aStartsWith !== bStartsWith) {
                        return aStartsWith - bStartsWith;
                    }


                    // 1. 库存非零优先（不排除零库存）
                    const aStock = a.total_single_count !== 0 ? 0 : 1;
                    const bStock = b.total_single_count !== 0 ? 0 : 1;
                    if (aStock !== bStock) {
                        return aStock - bStock;
                    }


                    // 3. 厚度升序排序，null最后
                    const aThickness = a.thickness;
                    const bThickness = b.thickness;

                    if (aThickness === null && bThickness === null) {
                        // 并列情况继续比较下个条件
                    } else if (aThickness === null) {
                        comparison = 1; // a排在后面
                    } else if (bThickness === null) {
                        comparison = -1; // b排在后面
                    } else {
                        const thicknessComparison = aThickness - bThickness;
                        if (thicknessComparison !== 0) {
                            comparison = thicknessComparison;
                        } else {
                            // 4. 处理*6米/*12米后缀
                            const aSuffix = this.getSuffixPriority(a.specification_name);
                            const bSuffix = this.getSuffixPriority(b.specification_name);
                            if (aSuffix < bSuffix) {
                                comparison = -1;
                            } else if (aSuffix > bSuffix) {
                                comparison = 1;
                            } else {
                                // 5. 更短名称优先
                                const aLength = a.specification_name.length;
                                const bLength = b.specification_name.length;
                                if (aLength < bLength) {
                                    comparison = -1;
                                } else if (aLength > bLength) {
                                    comparison = 1;
                                }/* else {
                                    // 6. 材质类型优先级
                                    const aCategory = a.category_name;
                                    const bCategory = b.category_name;
                                    const aPriority = this.getCategoryPriority(aCategory);
                                    const bPriority = this.getCategoryPriority(bCategory);
                                    comparison = aPriority - bPriority;
                                }*/
                            }
                        }
                    }

                    return comparison;
                })
                    .slice(0, 20); // 只取前20条
            }
        },

        getSuffixPriority(specName) {
            const priority = {
                "*6米": 0,
                "*12米": 1,
                "默认": 2
            };
            return priority[specName.endsWith("*6米") ? "*6米" :
                specName.endsWith("*12米") ? "*12米" : "默认"];
        },

        getCategoryPriority(category) {
            const priorityMap = {
                "方管": 0,
                "矩管": 0,
                "其他": 1
            };
            return priorityMap[category] || 1;
        },

        //查询仓库库存 , searchAll,row,mnGridTimeOut,rowIndex
        async $_searchByTotalSingleCount(query, searchType) {
            /*console.log('query',query)
            console.log('searchType',searchType)
            console.log('searchAll',searchAll)*/
            this.filterData = {
                bill_id_type : 'JC'
            }
            this.loading = true;
            if(!this.isBlankVue(query)){
                this.$_searchWarehouseStockLocal(query,searchType);
            }
           /* if('ProductQuotation' == searchType ){

            }else{
                await this.$axios({
                    method: 'post',
                    url: '/admin/specification/searchByTotalSingleCount',//searchByTotalSingleCount
                    params: {
                        keyword: query,
                        searchType: searchType,//如果是换仓，则不需要total
                        searchAll: searchAll
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response')
                    if (response.status == 200) {
                        this.loading = false;
                        //this.$store.getters.getSpecificationList = response.data.list
                        this.specificationDropDownTableData =response.data.list
                        //this.$store.dispatch('asyncUpdateSpecificationList',response.data.list);
                        /!*this.specificationDropDownTablePage.total = response.data.totalRow
                        this.specificationDropDownTablePage.currentPage = response.data.pageNumber
                        this.specificationDropDownTablePage.pageSize = response.data.pageSize*!/
                        //console.log(response)
                        if (mnGridTimeOut){
                            if (this.specificationDropDownTableData != null && this.specificationDropDownTableData.length > 0){
                                //console.log('row',row);
                                /!*console.log('query',query);
                                *!/
                                //如果回车的时候，row是空，说明输入太快了，默认选择第一个
                                if (this.isBlankVue(row.specification)){
                                    let item = this.specificationDropDownTableData[0];
                                    this.$_specificationSelect(item,row,searchType,'');
                                    this.$_searchWarehouseStockBySpecificationName(row, rowIndex)
                                    this.$_singleCountEventAndSumTopSome(row)
                                    this.$_getCutting(row);
                                }
                            }else{
                                row.specification = '';//没有结果，实际规格为空
                            }
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
            }*/

        },

        //查询客户 付款单位 || this.pageType == '开票发票'
        $_searchCustomerList(query) {
            if(this.pageType == '进仓单' || this.pageType == '出纳单'
                || this.pageType == '销售开单'  || this.pageType == '发货单'
                || this.pageType == '销售订单'  || this.pageType == '产品报价单'
                || this.pageType == '开单通知书'
            ){
                this.filterData= {}
                this.filterData.customer_name = query
                this.filterData.state = "激活"
            }else{
                this.filterData= {}
                this.filterData.customer_name = query
            }
            this.$axios({
                method: 'POST',
                url: '/admin/customers/search',
                data: {
                    filterData:this.filterData
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    //this.$data._customerList = response.data.page.list
                    this.$store.dispatch('asyncUpdateCustomerList',response.data.page.list);
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //查询供应商 收款单位
        $_searchSupplierList(query) {
            if(this.pageType == '进仓单' || this.pageType == '出纳单' || this.pageType == '销售开单' || this.pageType == '每天价格设置'){
                this.filterData.supplier_name = query
                this.filterData.state = "激活"
            }
            if(this.pageType == '每天价格设置'){
                //delete this.filterData.supplier_name
                //delete this.filterData.state
                delete this.filterData.bill_id_type
            }
            this.$axios({
                method: 'post',
                url: '/admin/supplier/searchSupplierList',
                data: {
                    filterData:this.filterData
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200) {
                    //this.$data._supplierList = response.data.page.list
                    this.$store.dispatch('asyncUpdateSupplierList',response.data.page.list);
                    //console.log(response)
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //单位筛选
        $_categoryListFilter(query) {
            //this.$data._categoryList = this.$data._categoryListDefault;
            //this.$data._categoryList = this.$data._categoryListDefault.filter(item => ( item.shortcut_key != null && (item.shortcut_key.indexOf(query) > -1 ) || item.name.indexOf(query) > -1));
            this.$store.dispatch('asyncUpdateCategoryList', this.$store.getters.getCategoryListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && (item.shortcut_key.toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1)));
            if(XEUtils.isEmpty(this.$store.getters.getCategoryList) || this.$store.getters.getCategoryList.length == 0){
                this.$store.dispatch('asyncUpdateCategoryList', this.$store.getters.getCategoryListDefault);
            }
        },
        //查询类别
        $_searchCategoryList(query) {
            this.$axios({
                method: 'post',
                url: '/admin/categorySetting/search',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    //this.$data._categoryList = response.data.page.list
                    //this.$data._categoryListDefault = response.data.page.list
                    this.$store.dispatch('asyncUpdateCategoryList', response.data.page.list);
                    this.$store.dispatch('asyncUpdateCategoryListDefault', response.data.page.list);
                    //console.log(response)
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },

        //业务员筛选 this.$data._salesManList
        $_salesManListFilter(query) {
            //this.$_getAccountListByRoleId('10');//业务员
            //console.log(query);
            this.$store.dispatch('asyncUpdateSalesManList', this.$store.getters.getSalesManListAll.filter(item => (!this.isBlankVue(item.shortcut_key) && !this.isBlankVue(query) && (item.shortcut_key.indexOf((query += '')) > -1)) || item.nick_name.indexOf((query += '')) > -1));
            //console.log('getSalesManList',this.$store.getters.getSalesManList)
            if(XEUtils.isEmpty(this.$store.getters.getSalesManList) || this.$store.getters.getSalesManList.length == 0){
                this.$store.dispatch('asyncUpdateSalesManList', this.$store.getters.getSalesManListAll);
            }
            /*this.$data._salesManListAll = this.$data._salesManList.filter(item => (!this.isBlankVue(item.shortcut_key) && !this.isBlankVue(query) && (item.shortcut_key.indexOf((query += '')) > -1)) || item.nick_name.indexOf((query += '')) > -1);
            if (this.$data._salesManListAll.length == 0) {
                this.$data._salesManListAll = this.$data._salesManList;
            }*/
        },
        //理计TheoryWeight
        $_theoryWeightListFilter(query) {
            this.$store.dispatch('asyncUpdateTheoryWeightList', this.$store.getters.getTheoryWeightListDefault.filter(item => (!this.isBlankVue(query) && (item.id == query) )));
            if(XEUtils.isEmpty(this.$store.getters.getTheoryWeightList) || this.$store.getters.getTheoryWeightList.length == 0){
                this.$store.dispatch('asyncUpdateTheoryWeightList', this.$store.getters.getTheoryWeightListDefault);
            }
        },
        //足厚
        $_fullThickListFilter(query) {
            this.$store.dispatch('asyncUpdateFullThickList', this.$store.getters.getFullThickListDefault.filter(item => (!this.isBlankVue(query) && (item.id == query) )));
            if(XEUtils.isEmpty(this.$store.getters.getFullThickList) || this.$store.getters.getFullThickList.length == 0){
                this.$store.dispatch('asyncUpdateFullThickList', this.$store.getters.getFullThickListDefault);
            }
            /*this.$data._fullThickList = this.$data._fullThickListDefault.filter(item => (!this.isBlankVue(query) && (item.id == query) ));
            if (this.$data._fullThickList.length == 0) {
                this.$data._fullThickList = this.$data._fullThickListDefault;
            }*/
        },
        //开单员筛选 this.$data._partOrderListAll
        $_partOrderListFilter(query) {
            //this.$_getAccountListByRoleId('14');//开单员
            this.$store.dispatch('asyncUpdatePartOrderList', this.$store.getters.getPartOrderListAll.filter(item => (!this.isBlankVue(item.shortcut_key) && !this.isBlankVue(query) && (item.shortcut_key.indexOf((query += '')) > -1)) || item.nick_name.indexOf((query += '')) > -1));
            if (XEUtils.isEmpty(this.$store.getters.getPartOrderList) || this.$store.getters.getPartOrderList.length == 0) {
                this.$store.dispatch('asyncUpdatePartOrderList', this.$store.getters.getPartOrderListAll);
            }
            /*this.$data._partOrderListAll = this.$data._partOrderList.filter(item => (!this.isBlankVue(item.shortcut_key) && !this.isBlankVue(query) && (item.shortcut_key.indexOf((query += '')) > -1)) || item.nick_name.indexOf((query += '')) > -1);
            if (this.$data._partOrderListAll.length == 0) {
                this.$data._partOrderListAll = this.$data._partOrderList;
            }*/
        },
        //仓库筛选
        $_warehouseListFilter(query) {
            //console.log(this.$store.getters.getWarehouseList)
            //console.log(this.$store.getters.getWarehouseListDefault)
            this.$store.dispatch('asyncUpdateWarehouseList', this.$store.getters.getWarehouseListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && (item.shortcut_key.toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1)));
            if (XEUtils.isEmpty(this.$store.getters.getWarehouseList) || this.$store.getters.getWarehouseList.length == 0) {
                this.$store.dispatch('asyncUpdateWarehouseList', this.$store.getters.getWarehouseListDefault);
            }
        },
        //经手人筛选
        $_handledByListFilter(query) {
            //console.log(this.$store.getters.getWarehouseList)
            //console.log(this.$store.getters.getWarehouseListDefault)
            this.$store.dispatch('asyncUpdateHandledByList', this.$store.getters.getHandledByListAll.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && (item.shortcut_key.toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.nick_name.toLowerCase().indexOf((query += '').toLowerCase()) > -1)));
            if (XEUtils.isEmpty(this.$store.getters.getHandledByList) || this.$store.getters.getHandledByList.length == 0) {
                this.$store.dispatch('asyncUpdateHandledByList', this.$store.getters.getHandledByListAll);
            }
        },
        //单位筛选
        $_unitListFilter(query) {
            this.$store.dispatch('asyncUpdateUnitList', this.$store.getters.getUnitListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && (item.shortcut_key.toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1)));
            //this._unitList = this._unitListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && (item.shortcut_key.toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1));
            if (XEUtils.isEmpty(this.$store.getters.getUnitList) || this.$store.getters.getUnitList.length == 0) {
                this.$store.dispatch('asyncUpdateUnitList', this.$store.getters.getUnitListDefault);
                //this._unitList = this._unitListDefault;
            }
        },
        //
        $_isOrderListFilter(query) {
            /*console.log(this.$store.getters.getIsOrderListDefault)
            console.log('query',query)*/
            //this.unitList = this.$store.getters.getUnitListDefault;
            //this.is_orderList = this.$store.getters.getIsOrderListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && ((item.shortcut_key+='').toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1));
            this.$store.dispatch('asyncUpdateIsOrderList', this.$store.getters.getIsOrderListDefault.filter(item => item.shortcut_key != null && !this.isBlankVue(query) && ((item.shortcut_key+='').toLowerCase().indexOf((query += '').toLowerCase()) > -1 || item.name.toLowerCase().indexOf((query += '').toLowerCase()) > -1)));
            if (XEUtils.isEmpty(this.$store.getters.getIsOrderList) || this.$store.getters.getIsOrderList.length == 0) {
                this.$store.dispatch('asyncUpdateIsOrderList', this.$store.getters.getIsOrderListDefault);
                //this.is_orderList = this.$store.getters.getIsOrderListDefault;
            }
        },
        //查询仓库
        $_searcWarehouseList(query) {
            this.$axios({
                method: 'post',
                url: '/admin/warehouseSetting/searchWarehouse',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    this.$store.dispatch('asyncUpdateWarehouseList', response.data.page.list);
                    this.$store.dispatch('asyncUpdateWarehouseListDefault', response.data.page.list);
                    //this.warehouseList = response.data.page.list
                    /*this.$data._warehouseList = response.data.page.list
                    this.$data._warehouseListDefault = response.data.page.list*/
                    //console.log(response)
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_tableRowClassName({row,rowIndex}) {
            if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                return 'green-row';
            }else if (rowIndex % 2 === 1){
                return 'success-row';
            } else {
                return '';
            }
        },
        //每行样式 main-row
        $_tableRowClassNameSuccess({rowIndex}) {
            if (rowIndex % 2 === 1) {
                return 'success-row';
            } else {
                return '';
            }
        },
        //结算方式
        $_getClearingFormList(query) {
            this.$axios({
                method: 'POST',
                url: '/admin/clearingForm',
                params: {
                    filterData: {pay_name: query},
                    sortData:{order_id :'asc'}
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.$store.dispatch('asyncUpdateClearingFormList', response.data.page.list);
                    //this.clearingFormList = response.data.page.list
                }
            }).catch((error) => {
                console.log(error)
            });
        },

        //其他费用
        $_getOtherExpensesList(query) {
            this.$axios({
                method: 'POST',
                url: '/admin/otherExpenses',
                params: {
                    filterData: {name: query},
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.$store.dispatch('asyncUpdateOtherExpensesList', response.data.page.list);
                    //this.$data._otherExpensesList = response.data.page.list
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //开单公司名
        $_getBillingCompanySettingList(query) {
            this.$axios({
                method: 'POST',
                url: '/admin/billingCompanySetting/search',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    //this.$data._billingCompanyList = response.data.page.list
                    this.$store.dispatch('asyncUpdateBillingCompanyList', response.data.page.list);
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //开单公司名
        /*$_findDefault() {
            this.$axios({
                method: 'POST',
                url: '/admin/billingCompanySetting/findDefault',
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.formData.billing_company_name = response.data.name
                }
            }).catch((error) => {
                console.log(error)
            });
        },*/
        //查询账户
        $_searchAccountList(query) {
            this.$axios({
                method: 'POST',
                url: '/admin/account/getAccountList',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    this.$store.dispatch('asyncUpdateAccountList', response.data.page.list);
                    //this.$data._accountList = response.data.page.list
                    //console.log(response)
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        /*$_changeWarehouseBillByKD(query){
            //判断如果需要修改，则判断结算金额是否存在，是的话提示“重置原来单据的金额”
            if (this.formData.cash_type_id != ''){
                if (this.getAllAmount > 0){
                    this.$XModal.confirm('您确定要更“换付款单”，回滚之前的结算金额?').then(type => {
                        if (type === 'confirm') {
                            this.$_searchWarehouseBillByKdOrJc(query);
                        }
                    })
                }
            }else{
                this.$_searchWarehouseBillByKdOrJc(query);
            }
        },*/
        //出纳 查询销售开单
        async $_searchWarehouseBillByKdOrJc(query) {
            if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD' || this.formData.id != '') {
                //console.log(this.formData.cash_type,'$_searchWarehouseBillByKdOrJc');
                await this.$axios({
                    method: 'post',
                    url: '/admin/warehouseBill/searchWarehouseBillByKdOrJc',
                    params: {
                        keyword: query,
                        cash_type : this.formData.cash_type,
                        cash_id: this.formData.id,
                        cash_type_id : this.formData.cash_type_id,
                        year: this.formData.year,
                        month: this.formData.month,
                    }
                }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.footerData',response.data.footerData)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.footerData = response.data.footerData
                    }
                }).catch((error) => {
                    console.log(error)
                });
            }
            //this.$XModal.alert('this.footerData' + this.footerData);
            //return Promise.resolve();
        },

        //出纳 判断该科目是否存在
        $_cheakHasSubject(row) {
            this.$axios({
                method: 'post',
                url: '/admin/subject/cheakHasSubject',
                params: {
                    keyword: row.subject_id_cash_data,
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response.data)
                //console.log(row)
                if (response.status == 200 && response.data.state != "ok") {
                    row.subject_id_cash_data = '';
                    row.subject_name_cash_data = '';
                    //console.log('response.data2',response.data)
                }else{
                    row.subject_name_cash_data = response.data.subject_name_cash_data;
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //出纳 查询科目 如果有辅助核算目
        $_searchSubjectList(query, searchSub,gridName) {
            this.$axios({
                method: 'post',
                url: '/admin/subject/searchSubject',
                params: {
                    keyword: query,
                    searchSub: searchSub,
                    currentPage: this.dropDownTablePage.currentPage,
                    pageSize: this.dropDownTablePage.pageSize,
                    cash_type :this.formData.cash_type,
                    gridName:gridName
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    this.$store.dispatch('asyncUpdateSubjectList', response.data.page.list);
                    //this.$data._subjectList = response.data.page.list
                    this.dropDownTableData = response.data.page.list
                    this.dropDownTablePage.pageSize = response.data.page.pageSize
                    this.dropDownTablePage.total = response.data.page.totalRow
                    this.dropDownTablePage.currentPage = response.data.page.pageNumber
                    //console.log(response)
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //往来单位
        $_searchAllCompany(query) {
            this.$axios({
                method: 'POST',
                url: '/admin/common/searchAllCompany',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status == 200) {
                    //this.$data._AllCompanyList = response.data
                    this.$store.dispatch('asyncUpdateAllCompanyList', response.data);
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //出纳 出票单位
        $_drawCompanySelectorFocusEvent({row}) {
            //console.log(row)
            if (!this.isBlankVue(row.draw_company) && this.$refs.draw_company != null && this.$refs.draw_company != 'undefined' && this.$refs.draw_company.selectedLabel != null && this.$refs.draw_company.selectedLabel != 'undefined') {
                setTimeout(() => {
                    this.$refs.draw_company.selectedLabel = row.draw_company;
                }, 1)
            }
        },
        //出纳 供应商、客户
        $_companyNameSelectorFocusEvent({row}) {
            //console.log(row)
            if (!this.isBlankVue(row.company_name) && this.$refs.company_name != null && this.$refs.company_name != 'undefined' && this.$refs.company_name.selectedLabel != null && this.$refs.company_name.selectedLabel != 'undefined') {
                setTimeout(() => {
                    this.$refs.company_name.selectedLabel = row.draw_company;
                }, 1)
            }
        },
        //出纳 出票单位
        $_drawCompanySelectorFocusEventFooter({row}) {
            //console.log(row)
            if (!this.isBlankVue(row.draw_company) && this.$refs.drawCompanyFooter != null && this.$refs.drawCompanyFooter != 'undefined' && this.$refs.drawCompanyFooter.selectedLabel != null && this.$refs.drawCompanyFooter.selectedLabel != 'undefined') {
                setTimeout(() => {
                    this.$refs.drawCompanyFooter.selectedLabel = row.draw_company;
                }, 1)
            }
        },
        //出纳 查询摘要
        $_searchSummaryList(query) {
            this.$axios({
                method: 'post',
                url: '/admin/summary/searchSummary',
                params: {
                    keyword: query,
                    currentPage: this.dropDownTablePageSummary.currentPage,
                    pageSize: this.dropDownTablePageSummary.pageSize,
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    this.$store.dispatch('asyncUpdateSummaryList', response.data.page.list);
                    //this.$data._summaryList = response.data.page.list
                    this.dropDownTableDataSummary = response.data.page.list
                    this.dropDownTablePageSummary.pageSize = response.data.page.pageSize
                    this.dropDownTablePageSummary.total = response.data.page.totalRow
                    this.dropDownTablePageSummary.currentPage = response.data.page.pageNumber
                    //console.log(response)
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //业务员、开单员
        $_getAccountListByRoleId(roleId) {
            this.$axios({
                method: 'post',
                url: '/admin/account/getAccountListByRoleId',
                params: {
                    role_id: roleId
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    if (roleId == '10') {
                        this.$store.dispatch('asyncUpdateSalesManList', response.data.accounts);
                        this.$store.dispatch('asyncUpdateSalesManListAll', response.data.accounts);
                        //this.$data._salesManList = response.data.accounts
                    } else if (roleId == '14') {
                        this.$store.dispatch('asyncUpdatePartOrderListAll', response.data.accounts);
                        this.$store.dispatch('asyncUpdatePartOrderList', response.data.accounts);
                        //this.$store.dispatch('asyncUpdatePartOrderList', response.data.accounts);
                        //this.$data._partOrderList = response.data.accounts
                    } else if (roleId == '16') {
                        this.$store.dispatch('asyncUpdateHandledByList', response.data.accounts);
                        this.$store.dispatch('asyncUpdateHandledByListAll', response.data.accounts);
                        //this.$data._handledByList = response.data.accounts
                    }
                    //console.log(response)
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //支数(可文本) -- 改为后端操作
        $_singleCountStrEvent(row) {
            if (this.isNumberVue(row.single_count_str)) {
                row.single_count = Number(row.single_count_str);
            } else {
                row.single_count = 0;
            }
        },

        //选择调出仓库,设置对应的调出仓库id
        $_selectWarehouseOut({row}) {
            //console.log('row.warehouseOut',row.warehouse_out )
            if (row != null && !this.isBlankVue(row.warehouse_out )) {
                let w = this.$store.getters.getWarehouseListDefault.filter(w => w.name == row.warehouse_out )
                //console.log('w',w);
                if (w.length > 0) {
                    row.warehouse_out_id = w[0].id
                } else {
                    row.warehouse_out_id = null;
                }
            } else {
                row.warehouse_out_id = null;
            }
        },
        //选择调出仓库,设置对应的调出仓库id
        $_selectWarehouseIn({row}) {
            if (row != null && !this.isBlankVue(row.warehouse)) {
                let w = this.$store.getters.getWarehouseListDefault.filter(w => w.name == row.warehouse)
                if (w.length > 0) {
                    row.warehouse_id = w[0].id
                } else {
                    row.warehouse_id = null;
                }
            } else {
                row.warehouse_id = null;
            }
        },
        //查询实际规格
        $_specificationSelect(item, row, searchType, currentPageName) {
            //校验所选的规格是否相等
            row.stock_bill_date_dd = new Date();
            /*if (this.isBlankVue(this.tableDataMain[0].stock_bill_date_dd)){
                row.stock_bill_date_dd = new Date();
            }else{
                row.stock_bill_date_dd = this.tableDataMain[0].stock_bill_date_dd;
            }*/
            if (!this.isBlankVue(item.specification_name)) {
                //取消自动输入规格
                //row.specification = item.specification_name;
                //console.log('2 row.specification',row.specification);
                row.unit = '支';
                if (this.formData.bill_id_type == 'JC'){
                    row.is_order = '否';
                }
            } else {
                row.stock_bill_date_dd = '';
                row.warehouse_out = '';
                row.warehouse_out_id = '';
                row.category = '';
                row.unit = '';
            }
            //console.log('执行$_specificationSelect ',item.warehouse_name)
            //console.log('item.warehouse_id ',item.warehouse_id)
            //console.log('searchType ',searchType)
            if (!this.isBlankVue(item.warehouse_name) && !this.isBlankVue(item.warehouse_id) && searchType == 'ReplaceWarehouseBill') {
                row.warehouse_out = item.warehouse_name;
                row.warehouse_out_id = item.warehouse_id;
                row.unit = '支';
            }
            /*this.$_selectWarehouseIn({row});
            this.$_selectWarehouseOut({row});*/
            //自动设置类别,根据前面两个值来对比判断
            /*if (!this.isBlankVue(row.specification) && row.specification.split("*").length >= 4) {
                const s = row.specification.split("*")
                const sideA = Number(s[0]);
                const sideB = Number(s[1]);
                if (sideA == sideB) {
                    row.category = '方管'
                } else {
                    row.category = '矩管'
                }
            }*/
            //console.log('item',item);
            row.category = item.category_name;
            //根据当前页是什么类型，开单通知书，需要自动读取第一行订单日期
            if (!this.isBlankVue(this.tableDataMain[0].unit)) {
                row.unit = this.tableDataMain[0].unit;
            }
            if (this.formData.bill_id_type == 'JC' && !this.isBlankVue(this.tableDataMain[0].warehouse) && !this.isBlankVue(this.tableDataMain[0].warehouse_id)) {
                row.warehouse = this.tableDataMain[0].warehouse;
                row.warehouse_id = this.tableDataMain[0].warehouse_id;
            }
            if (!this.isBlankVue(currentPageName) && currentPageName == 'KDTZS' && !this.isBlankVue(this.tableDataMain[0].stock_bill_date_dd)) {
                //row.stock_bill_date_dd = this.tableDataMain[0].stock_bill_date_dd;
                row.stock_bill_date_dd = new Date();
            }
        },
        //查询库存情况
        $_searchWarehouseStockBySpecificationName(row, rowIndex) {
            //console.log('rowIndex', rowIndex);
            if (row != null && rowIndex != null && rowIndex != 'undefined' && !this.isBlankVue(row.specification)) {
                //先清空行中的所有仓库库存支数
                for (var i in this.tableDataMain[rowIndex]) {
                    //console.log(i);
                    //console.log("先清空行中的所有仓库库存支数: ");
                    //let name = i.replaceAll("warehouse", "").trim();
                    let name = i.replace("warehouse", "").trim();
                    if (this.isNumberVue(Number(name))) {
                        this.tableDataMain[rowIndex][i] = null;
                    }
                }
                //查询库存情况
                this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchWarehouseStockBySpecificationName',
                    data: {
                        specification: row.specification,
                        category: row.category
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    //console.log(response.data)       //请求成功返回的数据
                    if (response.status === 200) {
                        //row = {...row, ...response.data}
                        for (var st in response.data) {
                            this.tableDataMain[rowIndex][st] = response.data[st];
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
            }
            this.$_isChangeSpecification();
        },
        //查询单位
        $_searchUnitList(query) {
            this.$axios({
                method: 'post',
                url: '/admin/unitSetting/search',
                params: {
                    keyword: query
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    //this.$store.dispatch('unitList', response.data.page.list);
                    //this.$store.dispatch('asyncUpdateUnitListDefault', response.data.page.list);
                    this.$store.dispatch('asyncUpdateUnitList', response.data.page.list);
                    this.$store.dispatch('asyncUpdateUnitListDefault', response.data.page.list);
                } else {
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //批量生成凭证
        $_batchCreateVoucher(batchCreateVoucherType,cashId,voucher_id){
            const loading = this.$loading({
                lock: true,
                text: '饮茶先啦，凭证中，请耐心等待!',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$axios({
                method: 'post',
                url: '/admin/voucher/batchCreateVoucher',
                data: {
                    filterData: this.filterData,
                    pickerDate: this.pickerDate,
                    billCategory: this.billCategory,
                    formData:this.formData,
                    voucherId:voucher_id,
                    batchCreateVoucherType:batchCreateVoucherType,
                    cash_id:cashId,
                }
            }).then((response) => {         //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                loading.close();
                if (response.data.state === "ok") {
                    this.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'success'
                    });
                    this.searchReceipt()
                } else {
                    //后端使用异步生成
                    this.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error'
                    });
                    if (response.data.errorList.length > 0){

                        this.$XModal.alert({content: response.data.errorList + '生成失败！', status: 'error' })
                    }
                    return
                }
            }).catch((error) => {
                loading.close();
                console.log(error)
            });
        },
        /**
         * 方向键
         * @param nextRef
         */
        $_focusNext(nextRef) {
            //console.log('$_focusNext');
            //console.log(this.$refs[nextRef]);
            if (!this.isBlankVue(this.$refs[nextRef])){
                this.$refs[nextRef].focus()
            }
        },
        $_blurNext(thisRef) {
            //this.$refs[thisRef].blur()
            //console.log(this.$refs[thisRef])
            if (!this.isBlankVue(this.$refs[thisRef])){
                this.$refs[thisRef].blur()
            }
        },

        //生成发货单
        $_createBillByPrintShippingAdvice(type) {
            if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                this.$message({
                    showClose: true,
                    message: '当前数据已被修改，请先保存，再打印！',
                    type: 'error'
                });
            } else {
                if (this.formData.bill_id_fh != '' && this.formData.bill_id_fh != null) {
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_fh : this.formData.bill_id_fh}})
                }else if(type == 'KD' && this.formData.bill_id_kd != null && this.formData.bill_id_kd != ''){
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_kd : this.formData.bill_id_kd,is_fhd:true}})
                } else if(type == 'KDTZS' && this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != ''){
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_kdtzs : this.formData.bill_id_kdtzs}})
                } else if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_kdtzs : this.formData.bill_id_kdtzs}})
                } else if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_kd : this.formData.bill_id_kd}})
                } else {
                    this.$message({
                        showClose: true,
                        message: '生成《销售开单》前，请先保存单据，单号不能为空！',
                        type: 'error'
                    });
                }
            }

        },
        //生成开单通知书
        $_createBillByTransferSlipInform() {
            //如果销售开单单号，不为空，则读取销售开单的信息，进行开单
            if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                this.$router.push({path: "/transferSlipInform", query: {bill_id_kdtzs : this.formData.bill_id_kdtzs}})
            } else {
                this.$message({
                    showClose: true,
                    message: '生成《开单通知书》前，请先保存单据，单号不能为空！',
                    type: 'error'
                });
            }
        },
        $_cellClickOpenBill({row, column}){
            if (column.property === 'bill_id_kdtzs' && !this.isBlankVue(row.bill_id_kdtzs)) {
                this.$router.push({path: "/transferSlipInform", query: {bill_id_kdtzs : row.bill_id_kdtzs}})
            }else if ((column.property === 'bill_id_kd' || column.property === 'bill_id_stock') && (!this.isBlankVue(row.bill_id_kd) || !this.isBlankVue(row.bill_id_stock))) {
                if(!this.isBlankVue(row.bill_id_kd)){
                    this.$router.push({path: "/transferSlip", query: {bill_id_kd: row.bill_id_kd}})
                }else{
                    const{href} = this.$router.resolve({path: '/transferSlip', query: {bill_id_kd : row.bill_id_stock}});
                    window.open(href,'_blank');
                }
            }else if (column.property === 'bill_id_fh' && !this.isBlankVue(row.bill_id_fh)) {
                this.$router.push({path: "/shippingAdvice", query: {bill_id_fh : row.bill_id_fh}})
            }
        },
        //生成销售开单
        $_createBillByTransferSlip() {
            //如果销售开单单号，不为空，则读取销售开单的信息，进行开单
            if (this.formData.bill_id_kd != '' && this.formData.bill_id_kd != null) {
                this.$router.push({path: "/transferSlip", query: {bill_id_kd: this.formData.bill_id_kd}})
            }else if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                this.$router.push({path: "/transferSlip", query: {bill_id_kdtzs : this.formData.bill_id_kdtzs}})
            } else {
                this.$message({
                    showClose: true,
                    message: '生成《销售开单》前，请先保存单据，单号不能为空！',
                    type: 'error'
                });
            }
        },

        /**
         * 前端权限控制
         * @param list
         * @returns {boolean}
         */
        $_getActionKeyListBoolean(list) {
            //console.log(this.$store.getters.getLoginAccount.actionKeyList)
            let b = false;
            if (this.$store.getters.getLoginAccount.isAdmin) {
                b = true;
                return b;
            }
            for (var num in list) {
                if (this.$store.getters.getLoginAccount.actionKeyList.indexOf('/admin' + list[num]) > -1) {
                    //console.log(list[num]);
                    b = true;
                    break;
                }
            }
            return b;
        },
        /**
         * xGrid 控制表格键盘方向键
         * @param rowId
         * @param columnIndex
         * @param columns
         * @param rowIndex
         * @param data
         * @param event
         * @param keyCode
         */
        $_onGridKeydownNext(rowId, columnIndex, columns, rowIndex, data, event, keyCode) {
            let nextColumn;
            //console.log(keyCode);
            let currentRow = event.$grid.getRowById(rowId)
            //let currentColumn = event.$grid.getColumnById(columnIndex)
           //console.log('columnIndex: ' + columnIndex)
/*            console.log('rowIndex')
            console.log(rowIndex)
            console.log('columnIndex')
            console.log(columnIndex)*/
            if (keyCode == 'enter') {
                nextColumn = columns.find((item, idx) => {
                    //console.log('idx: ' + idx)
                    //console.log('columnIndex: ' + columnIndex)
                    //console.log((idx > columnIndex) && !!item.editRender && item.className != 'unEditor' && item.title.indexOf('费') == -1)
                    //console.log('item2',item)
                    return (idx > columnIndex) && !!item.editRender && item.className != 'unEditor'  && item.title.indexOf('费') == -1;
                })
                //console.log(columnIndex != 10)
                //console.log(currentRow)
                //currentRow = event.$grid.getRowById(data[rowIndex + 1]._XID);
               // console.log(nextColumn)
            } else if (keyCode == 'left') {
                nextColumn = null;
                for (let i = 0; i < columns.length; i++) {
                    //console.log('columns[i]');
                    //console.log(columns[i]);
                    if (i < columnIndex && !!columns[i].editRender) {
                        nextColumn = columns[i]
                    }
                }
                //nextColumn = columns.lastIndexOf((item, idx) => idx < columnIndex && !!item.editRender > 0)
            } else if (keyCode == 'up' || keyCode == 'down') {
                if (keyCode == 'up' && rowIndex > 0) {
                    currentRow = event.$grid.getRowById(data[rowIndex - 1]._XID);
                    nextColumn = columns[columnIndex];
                } else if (keyCode == 'down' && rowIndex < data.length - 1) {
                    currentRow = event.$grid.getRowById(data[rowIndex + 1]._XID);
                    nextColumn = columns[columnIndex];
                }
            }
            //console.log(currentRow);
            if (currentRow) {
                //跳转下一行
                if (nextColumn) {
                    if (this.goBackRow){
                        event.$grid.setActiveCell(currentRow, nextColumn)
                        this.goBackRow = false;
                        return
                    }
                    //console.log('执行到 nextColumn',nextColumn.property);
                    //event.$grid.setSelectCell(currentRow, nextColumn)
                    //还有一个bug，不知道为什么数量太多的情况下，setSelectCell失效，只能用setActiveCell
                    if(nextColumn.property == 'subject_id_cash_data'){
                        event.$grid.setActiveCell(currentRow, nextColumn)
                    }else if (nextColumn.property == 'initial_balance' || nextColumn.property == 'accumulated_debits_current_year'
                        || nextColumn.property == 'cumulative_credit_current_year') {
                        event.$grid.setActiveCell(currentRow, nextColumn)
                        //如果是字段则不进行选中，直接激活编辑状态
                    } else if (nextColumn.property != 'remark_stock' && nextColumn.property != 'cash_data_remark' && nextColumn.property != 'company_name'
                        && nextColumn.property != 'draw_company'
                        && nextColumn.property != 'remark' && nextColumn.property != 'borrow_money' && nextColumn.property != 'lent_money') {
                        //console.log('执行到：setSelectCell')
                        event.$grid.setSelectCell(currentRow, nextColumn)
                    } else {
                        //console.log('执行到：else')
                        //event.$grid.setSelectCell(currentRow, nextColumn)
                        event.$grid.setActiveCell(currentRow, nextColumn)
                    }
                    // event.$grid.setSelectCell(currentRow, nextColumn)
                    //event.$grid.setActiveCell(currentRow, nextColumn)
                } else if (rowIndex > 0 && keyCode == 'left') {
                    this.$_onGridKeydownNext(data[rowIndex - 1]._XID, columns.length - 1, columns, rowIndex, data, event, 'left')
                    this.goBackRow = true;
                } else if (rowIndex < data.length - 1 && keyCode == 'enter') {
                    //console.log('执行到 enter 换行');
                    this.$_onGridKeydownNext(data[rowIndex + 1]._XID, 0, columns, rowIndex, data, event, 'enter')
                } /*else if (rowIndex > 0 && keyCode == 'up') {
                    //console.log('执行到 up' + columnIndex);
                    //this.$_onGridKeydownNext(data[rowIndex - 1]._XID, columnIndex,columns,rowIndex,data,event,'up')
                }*/
            }
        },

        //如果存在grid rowid 可能导致row._XID 失灵从而无法跳转
        $_onGridKeydown(event) {
            const data = event.$grid.getData();//获取数据
            const activeRecord = event.$grid.getActiveRecord();//获取当前激活的行
            const {row,rowIndex,columnIndex,$columnIndex,column} = activeRecord || event.$grid.getSelectedCell() || {};//根据  激活的行或者所选中的单元cell获取当前列ID、行ID、行
            let columnIndexMy = columnIndex;
            if (!this.isBlankVue($columnIndex)){
                columnIndexMy = $columnIndex
            }
            let rowIndexMy = rowIndex;
            /*if (!this.isBlankVue($rowIndex)){
                rowIndexMy = $rowIndex
            }*/
            const columns = event.$grid.getColumns();//获取当前列
            //console.log('row',row)
            if (row && row._XID && columnIndexMy >= 0) {
                //gridVoucher 凭证表
                if (event.$grid.$vnode.data.ref == 'gridVoucher' && event.$event.code == "Space" && (column.property == 'borrow_money' || column.property == 'lent_money')) {
                    //console.log('空格，判断是否存在借、贷，是的话对调');
                    //console.log('row', row);
                    let numBorrowMoney = row.borrow_money;
                    let numLentMoney = row.lent_money;
                    row.borrow_money = numLentMoney;
                    row.lent_money = numBorrowMoney;
                    return;
                } else if (event.$event.code == 'Enter' ||event.$event.code == 'NumpadEnter' || event.$event.code == 'ArrowRight') {
                    //判断下拉框是否存在，如果存在则不进行操作，否则直接继续执行回车下一个单元格
                    //console.log('进入回车')
                    //console.log('判断下拉框是否存在$_onGridKeydown');
                    if (event.$event.code == 'ArrowRight' && event.$grid.isActiveByRow(row)) {
                        return;
                    }
                    if ((this.xDownIsShow ) && (columns[columnIndexMy].property == 'single_count' )) {
                        //console.log('进入xDownIsShow1');
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.xDownSpecificationIsShow ) && (columns[columnIndexMy].property == 'specification' )) {
                        //console.log('进入xDownIsShow2');
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.xDownSummaryIsShow || this.xDownSummaryIsShowFooter || this.xDownSubjectIdIsShow || this.xDownSubjectIdIsShowFooter)
                        && (columns[columnIndexMy] == 'single_count' || columns[columnIndexMy].property == 'summary_cash_data'
                            || columns[columnIndexMy].property == 'subject_id_cash_data' || columns[columnIndexMy].property == 'subject_id_and_name')) {
                        //console.log('进入xDownIsShow3');
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    //console.log('应该能进行回车')
                    this.$_onGridKeydownNext(row._XID, columnIndexMy, columns, rowIndexMy, data, event, 'enter')
                } else if (event.$event.code == 'ArrowLeft') {
                    if (event.$grid.isActiveByRow(row)) {
                        return;
                    }
                    this.$_onGridKeydownNext(row._XID, columnIndexMy, columns, rowIndexMy, data, event, 'left')
                    //console.log('左箭头');
                } else if (event.$event.code == 'Backspace') {
                    //console.log('删除，即激活当前单元格');
                    /*if (columns[columnIndex].property == 'subject_id_cash_data'){
                        this.$refs.xDownSubjectId.showPanel();
                        this.xDownSubjectIdIsShow = true;
                    }*/
                    //event.$grid.setActiveCell(row, columns[columnIndex].property);
                } else if (event.$event.code == 'ArrowUp') {
                    //console.log('ArrowUp',columns[columnIndexMy].property)
                    //console.log('this.xDownIsShow',this.xDownIsShow)
                    if(columns[columnIndexMy].property == 'polish_roll') {
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.xDownIsShow && columns[columnIndexMy].property == 'single_count')) {
                        //console.log('进入1')
                        if (event.$grid.isActiveByRow(row)) {
                            //console.log('进入2')
                            return;
                        }
                    }
                    if ((this.xDownSpecificationIsShow ) && (columns[columnIndexMy].property == 'specification' )) {
                        console.log('进入xDownIsShow');
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.formData.bill_id_type == 'KD' && columns[columnIndexMy].property == 'single_count')
                        || columns[columnIndexMy].property == 'summary_cash_data'
                        || columns[columnIndexMy].property == 'subject_id_cash_data'
                        || columns[columnIndexMy].property == 'subject_id_and_name') {
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    this.$_onGridKeydownNext(row._XID, columnIndexMy, columns, rowIndexMy, data, event, 'up')
                    //console.log('上箭头');
                } else if (event.$event.code == 'ArrowDown') {
                    //console.log('this.xDownIsShow',this.xDownIsShow)
                    //console.log('columns[columnIndexMy].property',columns[columnIndexMy].property)
                    if(columns[columnIndexMy].property == 'polish_roll') {
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.xDownIsShow && columns[columnIndexMy].property == 'single_count')) {
                        //console.log('进入3')
                        if (event.$grid.isActiveByRow(row)) {
                            //console.log('进入4',event.$grid.getData())
                            return;
                        }
                    }
                    if ((this.xDownSpecificationIsShow && columns[columnIndexMy].property == 'specification')) {
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    if ((this.formData.bill_id_type == 'KD' && columns[columnIndexMy].property == 'single_count')
                        || columns[columnIndexMy].property == 'summary_cash_data'
                        || columns[columnIndexMy].property == 'subject_id_cash_data'
                        || columns[columnIndexMy].property == 'subject_id_and_name') {
                        if (event.$grid.isActiveByRow(row)) {
                            return;
                        }
                    }
                    this.$_onGridKeydownNext(row._XID, columnIndexMy, columns, rowIndexMy, data, event, 'down')
                    //console.log('下箭头');
                }else if(event.$event.code == 'Tab'){
                    this.checkSpecification(row);
                } else if (event.$grid.$vnode.data.ref == 'gridVoucher') {
                    //激活当前输入框，用于凭证金额输入页面，切换手动判断并激活编辑状态
                    /*console.log("激活当前输入框",event);
                    console.log("激活当前输入框",event.$grid);*/
                    event.$grid.setActiveCell(row, columns[columnIndexMy].property);
                }
            }
        },
        setIsBillCancel(row) {
            let id = row.bill_id;
            let str = '';
            if (row.bill_id_type == 'KDTZS') {
                id = row.bill_id_kdtzs;
            } else if (row.bill_id_type == 'FH') {
                id = row.bill_id_fh
            } else if (row.bill_id_type == 'DD') {
                id = row.bill_id_dd
                str = '\n订单作废，将会自动释放占用库存，状态自动设置为“完成”';
            } else if (row.bill_id_type == 'BJ' || row.bill_id_type == 'JC' || row.bill_id_type == 'CC' || row.bill_id_type == 'HC') {
                id = row.bill_id;
            } else if (row.bill_id_type == 'KD') {
                id = row.bill_id_kd
            }
            this.$XModal.confirm('您确定要作废：' + id + '单号?作废后不能修改，不能反作废。' + str).then(type => {
                //const $table = this.$refs.xTable
                if (type === 'confirm') {
                    //传送删除动作
                    this.$axios({
                        method: 'post',
                        url: '/admin/transferSlip/setIsBillCancel',
                        params: {
                            id: row.b_id
                        }
                    }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'success'
                            });
                            this.getAllWarehouse();
                        } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                            return
                        }
                    }).catch((error) => {
                        console.log(error)
                    });
                }
            })
        },
        //出纳 判断该科目是否存在子类
        $_isHasFatherId(row, searchSub, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow,gridName) {
            //console.log('1xDownSubjectIdIsShow',xDownSubjectIdIsShow);
            //console.log('1this.$refs[xDownSubjectIdIsShow]',this.$data[xDownSubjectIdIsShow]);
            this.$axios({
                method: 'post',
                url: '/admin/subject/isHasFatherId',
                params: {
                    keyword: row.id_subject
                }
            }).then((response) => {         //这里使用了ES6的语法
                //(response)       //请求成功返回的数据
                //console.log(response)
                if (response.status == 200 && response.data.state == "ok") {
                    //如果有子类，则继续再查询
                    this.$data[xDownSubjectIdIsShow] = true;
                    this.$_searchSubjectList(row.id_subject, searchSub,gridName)
                } else {
                    //console.log('mainGridRef',mainGridRef)
                    //console.log('dropDownGridRef',dropDownGridRef)
                    //console.log('2xDownSubjectIdIsShow',xDownSubjectIdIsShow)
                    //console.log('2xDownSubjectIdIsShow',this.$data[xDownSubjectIdIsShow])
                    const activeRow = this.$refs[mainGridRef].getActiveRecord().row;
                    //console.log('activeRow: ', activeRow);
                    //出纳单据
                    if (Object.prototype.hasOwnProperty.call(activeRow, "subject_id_cash_data")) {
                        activeRow.subject_id_cash_data = row.id_subject;
                    }
                    if (Object.prototype.hasOwnProperty.call(activeRow, "subject_name_cash_data")) {
                        activeRow.subject_name_cash_data = row.subject_name;
                    }
                    //会计凭证
                    if (Object.prototype.hasOwnProperty.call(activeRow, "subject_id")) {
                        activeRow.subject_id = row.id_subject;
                    }
                    if (Object.prototype.hasOwnProperty.call(activeRow, "subject_name")) {
                        activeRow.subject_name = row.subject_name;
                    }
                    if (Object.prototype.hasOwnProperty.call(activeRow, "subject_id_and_name")) {
                        activeRow.subject_id_and_name = row.id_subject + " " + row.subject_name;
                    }
                    this.$data[xDownSubjectIdIsShow] = false;
                    //console.log('2this.$refs[xDownSubjectIdIsShow]',this.$data[xDownSubjectIdIsShow])
                    this.$refs[vxePulldown].hidePanel();
                    //未完成，目前只支持往来单位,如果多个的话，就弹不出来了，后期需要参考其他会计软件，弹出框再选择
                    //需要判断一下这个科目是否存在辅助核算目，如果存在则查询辅助核算
                    if (row.company_name_assist_accounting) {
                        //存在辅助核算目，需要继续继续弹出公司的筛选
                        //弹出框继续选择往来单位
                        //alert(this.$refs.CashDataxGrid.getActiveRecord())
                        this.tempRow = activeRow;
                        this.tempGrid = mainGridRef;
                        //this.$_okSetAssist(row);
                        this.showAssistModel = true
                    }
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        //设置辅助核算
        $_okSetAssist() {
            /*console.log('tempGrid:',this.$refs[this.tempGrid].getData(this.tempRowIndex));
            this.$refs[this.tempGrid].getData(this.tempRowIndex).company_name_assist = this.tempCompanyAssistModel.company_name;
            this.$refs[this.tempGrid].getData(this.tempRowIndex).company_uuid_assist = this.tempCompanyAssistModel.company_uuid;*/
            this.tempRow.company_name_assist = this.tempCompanyAssistModel.company_name;
            this.tempRow.company_uuid_assist = this.tempCompanyAssistModel.company_uuid;
        },
        //封装一个单元cell点击之后触发的请求方法
        async $_cellDoubleClickEvent(type, url, params) {
            if (type === 'confirm') {
                await this.$axios({
                    method: 'post',
                    url: url,
                    params: params
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '设置成功！',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                    }
                    //this.getAllWarehouse();//调用的位置再查一遍
                }).catch((error) => {
                    console.log(error)
                });
            }
            //return Promise.resolve();
        },
        //插入行,清空obj值
        // eslint-disable-next-line no-unused-vars
        async $_insertRowEvent(dataTable,xTable,row,rowIndex){
            const record = Object.assign({}, row)
            await Object.keys(record).forEach(key => {
                if(key != 'cash_type' && key != 'operateVisible'){
                    (record[key] = '')
                }
            });
            await xTable.insertAt(record, row)
            await dataTable.splice(rowIndex,0,record);
        },
        //复制行
        async $_insertCopyRowEvent(dataTable,xTable,row,rowIndex){
            console.log(this);
            console.log(this.pageType);
            const record = Object.assign({}, row)
            if(this.pageType == '销售订单' || this.formData.bill_type == '销售订单'){
                Object.keys(record).forEach(key => {
                    if(key == 'batch_number' ||  key == 'dd_is_ok_count'||  key == 'dd_state_name'){
                        (record[key] = 0)
                    }else if(key == '_XID' ||  key == 'id'){
                        (record[key] = null)
                    }else if(key == 'dd_is_ok' ){
                        (record[key] = 0)
                    }
                });
            }else{
                Object.keys(record).forEach(key => {
                    if(key == '_XID' || key == 'id' ||  key == 'batch_number'){
                        (record[key] = null)
                    }else if(key == 'dd_is_ok' ){
                        (record[key] = 0)
                    }
                    /*if(key == '_XID' || key == 'id' ||  key == 'batch_number' ||  key == 'dd_is_ok_count' ||  key == 'dd_state_name' ||  key == 'automatically'){
                        (record[key] = '')
                    }else if(key == 'dd_is_ok' ){
                        (record[key] = 0)
                    }*/
                });
            }

            await xTable.insertAt(record, row)
            dataTable.splice(rowIndex+1,0,record);
            this.$_singleCountEventEvery();
        },
        //删除行
        async $_deleteRowEvent(dataTable,xTable,row,rowIndex){
            await xTable.remove(row)
            dataTable.splice(rowIndex,1);
            this.$_singleCountEventEvery();
        },
        //打印日期
        printTime(bill_id_type,billId){
            this.$axios({
                method: 'POST',
                url: '/admin/common/printTime',
                params: {
                    bill_id_type: bill_id_type,
                    bill_id: billId,
                }
            })
        },
        //读取对应的序号，获取相关的规格资料属性
        $_serialNumberEvent(row){
            console.log('row.serial_number:' + row.serial_number);
            let topRow = this.tableDataTop[row.serial_number -1];
            //row.specification = topRow.specification
            row.specification = topRow.specification.slice(0,topRow.specification.indexOf("米")+1);
            row.category = topRow.category;
            row.unit = topRow.unit;
            row.single_count = topRow.single_count;
            row.warehouse = topRow.warehouse_out;
            row.warehouse_id = topRow.warehouse_out_id;
            row.weighing_heavy = '';
            //row.weighing_heavy = topRow.weighingHeavy
            row.remark_stock = topRow.remark_stock;
            row.automatically = topRow.automatically;
            row.id = '';//设置空，说明需要重新更新
            row.batch_number = '';//设置空，说明需要重新更新
            this.$_getCutting(row);
            this.$_getWallThickness(row);
        },
        //反过来查找，下面表的row同步automatically
        $_automaticallyEvent(row){
            let footerRow;
            for (let t in this.tableDataFooter) {
                if (this.tableDataFooter[t].serial_number == row.order_id){
                    footerRow = this.tableDataFooter[t]
                    footerRow.automatically = row.automatically;
                }
            }

        },


        //设置切割长度
        $_setCuttingEvent(row){
            if (!this.isBlankVue(row.cutting)){
                row.cutting = parseFloat(row.cutting);
            }else{
                this.$_getCutting(row)
            }

            if (!this.isBlankVue(row.specification) ) {
                if (row.specification.split("*").length >= 4){
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    const wallThickness = Number(s[2]);
                    if (s[3].indexOf("米") != -1){
                        //const lengthExtent = s[3].replace("米", "")
                        const theoretical_weight = (((sideA + sideB) * 2 - 4 * wallThickness) * wallThickness * 0.00785 * Number(row.cutting)) / 1000;
                        row.theoretical_weight = this.$XEUtils.round(theoretical_weight,6);
                        if (row.single_count != null) {
                            row.theoretical_weight_single_count = this.$XEUtils.round(this.$XEUtils.multiply(theoretical_weight , Number(row.single_count)),6);
                        }
                    }
                    if (row.single_count != null) {
                        row.weighing_heavy = this.$XEUtils.round(row.theoretical_weight_single_count,6);//过磅重
                    }
                    row.specification = sideA + '*' + sideB + '*' + wallThickness + '*' + parseFloat(row.cutting) + s[3].slice(s[3].indexOf("米"), s[3].length)
                }
            }
        },
        //设置厚度
        $_setWallThicknessEvent(row){
            if (!this.isBlankVue(row.wall_thickness)){
                row.wall_thickness = parseFloat(row.wall_thickness);
            }else{
                this.$_getWallThickness(row)
            }

            if (!this.isBlankVue(row.specification) ) {
                if (row.specification.split("*").length >= 4){
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    const wallThickness = row.wall_thickness;
                    if (s[3].indexOf("米") != -1){
                        //const lengthExtent = s[3].replace("米", "")
                        const theoretical_weight = (((sideA + sideB) * 2 - 4 * wallThickness) * wallThickness * 0.00785 * Number(row.cutting)) / 1000;
                        row.theoretical_weight = this.$XEUtils.round(theoretical_weight,6);
                        if (row.single_count != null) {
                            row.theoretical_weight_single_count = this.$XEUtils.round(this.$XEUtils.multiply(theoretical_weight , Number(row.single_count)),6);
                        }
                    }
                    if (!this.isBlankVue(row.single_count)) {
                        row.weighing_heavy = this.$XEUtils.round(row.theoretical_weight_single_count,6);//过磅重
                    }
                    row.specification = sideA + '*' + sideB + '*' + wallThickness + '*' + parseFloat(row.cutting) + s[3].slice(s[3].indexOf("米"), s[3].length)
                }
            }
        },

        //根据规格返回厚度
        $_getWallThickness(row){
            if (!this.isBlankVue(row.specification) ) {
                if (row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const wallThickness = Number(s[2]);
                    row.wall_thickness = wallThickness;
                }
            }
        },
        //获取长度
        $_getCutting(row){
            //console.log('cutting',row);
            if (!this.isBlankVue(row.specification) && row.specification.split("*").length >= 4){
                const s = row.specification.split("*")
                if (s[3].indexOf("米") != -1){
                    row.cutting = Number(s[3].slice(0, s[3].indexOf("米") + 1).replace("米", ""));
                }
            }
        },
        //当前页、新一页打开
        $_openType(obj){
            const{href } = this.$router.resolve(obj);
            window.open(href,'_blank');
        },
        $_openTypeSelf(obj){
            this.$router.push(obj);
        },
        $_openBill(row,type){
            //console.log(row)
            //console.log(row.stock_type)
            let obj = '';
            if (row.type == 'SKD' || row.type == 'FKD' || row.type == 'YBFYD'){
                obj = {path: '/receipt', query: {id: row.id }}
            }else if (row.type == 'KD' || row.bill_id_type == 'KD' || row.stock_type == 'KD'){
                if (type == 'print'){
                    if (!this.isBlankVue(row.bill_id)){
                        obj = {path: '/printTransferSlip', query: {bill_id_kd : row.bill_id}}
                    }else {
                        obj = {path: '/printTransferSlip', query: {bill_id_kd : row.bill_id_kd}}
                    }
                }else{
                    if (!this.isBlankVue(row.bill_id_stock)){
                        obj = {path: '/transferSlip', query: {bill_id_kd : row.bill_id_stock }}
                    }else if (this.isBlankVue(row.bill_id_kd)){
                        obj = {path: '/transferSlip', query: {bill_id_kd : row.bill_id }}
                    }else {
                        obj = {path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd}}
                    }
                }
            }else if((row.type == 'HC' || row.bill_id_type == 'HC')){
                if (this.isBlankVue(row.bill_id_kd)){
                    obj = {path: '/replaceOutWarehouse', query: {bill_id: row.bill_id}}
                }else {
                    obj = {path: '/replaceOutWarehouse', query: {bill_id: row.bill_id_kd}}
                }
            }else if (row.type == 'JC' || row.bill_id_type == 'JC' || row.bill_id_type == 'JG-JC'){
                obj =  {path: '/intoTheWarehouse', query: {bill_id: row.bill_id }}
            }else if (row.type == 'QTSRD'){
                obj = {path: '/receipt', query: {id: row.id }}
            }else if(row.type == 'QC'){
                this.$message({
                    showClose: true,
                    message: '期初数，请到账套初始化中查看！',
                    type: 'error'
                });
                return
            }
            //console.log(obj)
            this.$_openType(obj);
        },
        //一览的样式
        $_cellClassNameSummary ({row, column}) {
            if (column.property === 'bill_id_kdtzs' && !this.isBlankVue(row.bill_id_kdtzs)) {
                return 'col-success'
            }
            else if ((column.property === 'bill_id_kd' || column.property === 'bill_id_stock')  && (!this.isBlankVue(row.bill_id_kd) || !this.isBlankVue(row.bill_id_stock))) {
                return 'col-success'
            }
            else if (column.property === 'bill_id_fh'  && !this.isBlankVue(row.bill_id_fh)) {
                return 'col-success'
            }
            else if (column.property === 'kdcc_state_man' ) {
                if (row.kdcc_state_man) {
                    return 'col-success'
                }else{
                    return 'col-fail'
                }
            }else if(column.property === 'handled_by' && this.filterData.bill_id_type == 'KD'){
                //经手人，并且是销售开单一览
                if (row.handled_by) {
                    return 'col-success'
                }else{
                    return 'col-fail'
                }
            }else if(column.property === 'cn_state_man'){
                if (row.cn_state_man) {
                    return 'col-success'
                }else{
                    return 'col-fail'
                }
            }else if(column.property === 'cash_type_id_list'){
                return 'col-ableClick'
            }else if(column.property === 'customer' || column.property === 'supplier' ){
                return 'pull-left-text'
            }
        },

        //双击提交状态，直接提交当前商品ID，设置对应反选状态
        $_cellClickEventKD ({row,rowIndex, column }) {
            if (column.property =='kdcc_state_man'){
                //let str = '正进行“出仓审核”'
                let isSetMan = true;
                if (!this.isBlankVue(row.kdcc_state_man)){
                    isSetMan = false;//取消出仓审核
                    //str = '取消“出仓审核”'
                }
                let params = {
                    id:row.id,
                    isSetMan:isSetMan
                };
                //console.log('kdcc_state_man')
                //console.log(this.formData.bill_id_type)
                //生成过来，未保存单据
                if (this.searchType == 'transferSlip' && this.formData.bill_id_type !='KD'){
                    this.$message({
                        showClose: true,
                        message: '请先保存单据',
                        type: 'error'
                    });
                    return;
                }
                if (this.formData.bill_id_type =='KD'){
                    //在销售开单里面的详细页面内，需要执行保存，因为选择了经手人
                    this.$_cellDoubleClickEvent('confirm','/admin/allWarehouse/KDCCState',params).then(() => {
                        this.searchWarehouseBill();
                    });
                }else if(this.filterData.bill_id_type == 'KD'){
                    this.$_cellDoubleClickEvent('confirm','/admin/allWarehouse/KDCCState',params).then(() => {
                        this.getAllWarehouse();
                    });
                }

                /*if (!this.isBlankVue(row.kdcc_state_man)){
                    isSetMan = false;//取消出仓审核
                    str = '取消“出仓审核”'
                }
                str = str + '\n销售开单-单号：'+row.billIdKd+'\n规格：'+row.specification+',数量:'+row.single_count +'个库存,仓库:'+row.warehouseOut
                this.$XModal.confirm(str).then(type => {
                    if (type === 'confirm') {
                        //const $table = this.$refs.xTable
                        let params = {
                            id:row.id,
                            isSetMan:isSetMan
                        };
                        this.$_cellDoubleClickEvent(type,'/admin/allWarehouse/KDCCState',params).then(() => {
                            this.getAllWarehouse();
                        });
                    }
                })*/
            }else if (column.property =='cn_state_man'){
                //let str = '正进行“出纳审核”'
                let isSetMan = true;
                if (!this.isBlankVue(row.cn_state_man)){
                    isSetMan = false;//取消出仓审核
                    //str = '取消“出纳审核”'
                }
                //str = str + '\n销售开单-单号：'+row.billIdKd+'\n合计金额: ' + row.total_amount + '元'
                let params = {
                    bill_id:row.bill_id_kd,
                    isSetMan:isSetMan
                };
                this.$_cellDoubleClickEvent('confirm','/admin/allWarehouse/CNStateMan',params).then(() => {
                    this.getAllWarehouse();
                });
            } else if (column.property =='cash_type_id_list'){
                this.showModel.bill_id = row.bill_id_kd;
                this.showModel.isShow = true;
                this.findCDWBListByBillId();

            }else if (column.property == 'dd_state_name'){
                this.releaseOrLockForm.id = row.id
                let str = '将会“释放已锁定”的规格：'+row.specification+',数量:'+row.single_count +'个库存,仓库:'+row.warehouse_out +'?'
                if (row.dd_state_name == '完成'){
                    str = '已完成状态，无法重新锁定！'
                    this.$message({
                        showClose: true,
                        message: str,
                        type: 'error'
                    });
                    return;
                }else{
                    this.releaseOrLockForm.message = str
                    this.releaseOrLockForm.ddIsOkCount = this.$XEUtils.subtract(row.single_count,row.dd_is_ok_count)
                    this.releaseOrLockForm.showIsOkCount = true
                }
            }else if (column.property == 'dd_state_name2'){
                this.releaseOrLockForm.id = row.id
                let str = '将会“释放已锁定”的规格：'+row.specification+',数量:'+row.single_count +'个库存,仓库:'+row.warehouse_out +'?'
                if (row.dd_state_name2 == '完成'){
                    str = '已完成状态，无法重新锁定！'
                    this.$message({
                        showClose: true,
                        message: str,
                        type: 'error'
                    });
                    return;
                }else{
                    this.releaseOrLockForm.message = str
                    this.releaseOrLockForm.ddIsOkCount = this.$XEUtils.subtract(row.single_count,row.dd_is_ok_count)
                    this.releaseOrLockForm.showIsOkCount = true
                }
            }else if(column.property == 'dd_stock_id'){
                    this.filterData = {
                        customer : this.formData.customer,
                        //only_bill_id : this.formData.bill_id_dd,
                        specification : row.specification,
                        category : row.category
                    }
                    this.sortData = {}
                    this.DDStockIdIsShow = true
                    this.rowMain = row;
                    this.rowIndexMain = rowIndex;
                    this.searchDDStockList()
            }else if (column.property === 'bill_id_kdtzs' && !this.isBlankVue(row.bill_id_kdtzs)) {
                this.$router.push({path: "/transferSlipInform", query: {bill_id_kdtzs : row.bill_id_kdtzs}})
            }else if (column.property === 'bill_id_kd' && !this.isBlankVue(row.bill_id_kd)) {
                this.$router.push({path: "/transferSlip", query: {bill_id_kd: row.bill_id_kd}})
            }else if (column.property === 'bill_id_fh' && !this.isBlankVue(row.bill_id_fh)) {
                this.$router.push({path: "/shippingAdvice", query: {bill_id_fh : row.bill_id_fh}})
            }
        },
        releaseOrLock(){
            if (this.releaseOrLockForm.ddIsOkCount <0){
                this.$message({
                    showClose: true,
                    message: '不能释放负数',
                    type: 'error'
                });
                return
            }else{
                this.$axios({
                    method:'post',
                    url:'/admin/allWarehouse/releaseOrLock',
                    data: {
                        id:this.releaseOrLockForm.id,
                        ddIsOkCount:this.releaseOrLockForm.ddIsOkCount
                    }
                }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '设置成功！',
                            type: 'success'
                        });
                        this.releaseOrLockForm.showIsOkCount = false;
                        if (this.searchType == 'salesOrdersSingle'){
                            this.$_searchSalesOrdersSingle();
                        }else{
                            this.getAllWarehouse();
                        }
                    }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        this.getAllWarehouse();
                        return
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            }
        },
        $_searchSalesOrdersSingle() {
            this.loading = true;
            this.$axios({
                method: 'post',
                url: '/admin/salesOrdersSingle/search',
                params: {
                    bill_id_dd: this.formData.bill_id_dd,
                },
            }).then((response) => {          //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                this.loading = false;
                if (response.status === 200 && response.data.state == 'ok') {
                    this.isAddBill = false;
                    this.formData = response.data.warehouseBill;
                    if (!this.isBlankVue(this.formData.remark_warehouse_bill)) {
                        this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.toString().replace('，', ',').split(',');
                    }
                    //console.log('response.data.stocks',response.data.stocks);
                    this.tableDataMain = this.$_createRowData(response.data.stocks);
                } else {
                    this.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error'
                    });
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_sumNum(list, field) {
            let count = Number(0)
            let all_fees_list = [];
            list.forEach(item => {
                if (field == 'all_fees' && all_fees_list.indexOf(item['bill_id_kd']) == -1){
                    //合计杂费，只合计一份单一次数据
                    count = this.$XEUtils.add(count,item[field]);
                    all_fees_list.push(item['bill_id_kd']);
                    //console.log('all_fees_list',all_fees_list);
                }else if (field != 'all_fees'){
                    count = this.$XEUtils.add(count,item[field]);
                }
            })
            if (field == 'weighing_heavy'){
                return this.$XEUtils.round(count,6)
            }else{
                return this.$XEUtils.round(count,3)
            }
        },
        $_footerMethod({columns, data}) {
            const sums = []
            columns.forEach((column, columnIndex) => {
                if (columnIndex === 0) {
                    sums.push('合计')
                } else {
                    if (column.property === 'single_count_total' || column.property === '厂一'
                        || column.property === '厂二' || column.property === '仓一'
                        || column.property === '仓二'
                        || column.property === '仓三' || column.property === '仓四'
                        || column.property === '新门市'
                    ) {
                        sums.push(this.$_sumNum(data, column.property))
                    } else {
                        sums.push('')
                    }
                }
            })
            //this.autoComputed();
            // 返回一个二维数组的表尾合计
            return [sums]
        },
        //回显element-select问题
        $_selectorFocusEvent(ref,obj) {
                setTimeout(() => {
                    this.$refs[ref].selectedLabel = obj;
                    //row.specification=this.$refs.selectorSpecification.selectedLabel;
                }, 5)
        },
        $_pickerMonthRangeEvent(){
            this.pickerDate = [this.$XEUtils.getWhatMonth(this.pickerMonthRange[0], 0, 'first'),this.$XEUtils.getWhatMonth(this.pickerMonthRange[1], 0, 'last')]
        },
        $_pickerMonthEvent(){
            this.pickerDate = [this.$XEUtils.getWhatMonth(this.pickerDateMonth, 0, 'first'),this.$XEUtils.getWhatMonth(this.pickerDateMonth, 0, 'last')]
        },
        $_pickerDateEvent(){
            this.pickerDate = [this.$XEUtils.getWhatMonth(this.pickerDateMonth, 0, 'first'),this.$XEUtils.getWhatMonth(this.pickerDateMonth, 0, 'last')]
        },
        $_pickerDateChangePickerMonthRangeEvent(){
            this.pickerMonthRange = this.pickerDate
        },
        //单据下一页
        $_BackOrGo(id,billIdType,warehouseBackOrGo,type,isPrint,companyBackOrGo){
            let p  = {
                id: id,
                bill_id_type:billIdType,
                type:type,
                warehouseBackOrGo:warehouseBackOrGo.id,
            }
            if (companyBackOrGo != null){
                p  = {
                    id: id,
                    bill_id_type:billIdType,
                    type:type,
                    warehouseBackOrGo:warehouseBackOrGo.id,
                    company_name:companyBackOrGo.company_name,
                    company_uuid:companyBackOrGo.company_uuid,
                }
            }
            this.$axios({
                method: 'post',
                url: '/admin/common/BackOrGo',
                params: p
            }).then((response) => {          //这里使用了ES6的语法
                if (response.status == 200 && response.data.state == "ok") {
                    let url = '/intoTheWarehouse';//进仓单
                    if (isPrint){
                        if(billIdType == 'KD'){
                            url = '/printTransferSlip';//销售开单
                            this.$router.push({path: url, query: {
                                bill_id_kd: response.data.only_bill_id,
                                    BackOrGoId:warehouseBackOrGo.id,
                                    BackOrGoName:warehouseBackOrGo.name,
                                    company_name:companyBackOrGo.company_name,
                                    company_uuid:companyBackOrGo.company_uuid
                            }})
                        }else{
                            this.$router.push({path: url, query: {
                                bill_id: response.data.only_bill_id,
                                    BackOrGoId:warehouseBackOrGo.id,
                                    BackOrGoName:warehouseBackOrGo.name,
                                    company_name:companyBackOrGo.company_name,
                                    company_uuid:companyBackOrGo.company_uuid
                            }})
                        }
                    }else{
                        if(billIdType == 'KD'){
                            url = '/transferSlip';//销售开单
                            this.$router.push({path: url, query: {bill_id_kd: response.data.only_bill_id,BackOrGoId:warehouseBackOrGo.id,BackOrGoName:warehouseBackOrGo.name}})
                        }else{
                            this.$router.push({path: url, query: {bill_id: response.data.only_bill_id,BackOrGoId:warehouseBackOrGo.id,BackOrGoName:warehouseBackOrGo.name}})
                        }
                    }

                } else {
                    this.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error'
                    });
                    return
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        $_isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        $_scrollEvent({isY,$event}){
            if(this.loading){
                return false
            }
            let that = this
            //防抖 节流
            if (that.debounceTime) {
                clearTimeout(this.debounceTime)
            }
            that.debounceTime = setTimeout(function () {
                if (isY){
                    let isBottom = $event.target.scrollHeight - $event.target.offsetHeight - $event.target.scrollTop
                    // 向上取整
                    let maxPage = Math.ceil(that.tablePage.total / that.tablePage.pageSize)
                    console.log(maxPage)
                    if (isBottom <= 0 && maxPage > that.tablePage.currentPage) {
                        that.tablePage.currentPage = that.tablePage.currentPage + 1
                        that.getData(null).then((result)=>{
                            let data = result
                            console.log(data)
                            that.tableDataMain = that.tableDataMain.concat(data.page.list);
                            that.tablePage.pageSize = data.page.pageSize
                            that.tablePage.total = data.page.totalRow
                            that.tablePage.currentPage = data.page.pageNumber
                        })
                        console.log('继续加载')
                    }

                }
                that.debounceTime = undefined;
            }, 80)
        },
        //打磨驳口 提示回调
        $_polishRollQuerySearch(queryString, cb){
            var polishRoll = this.polishRoll;
            var results = queryString ? polishRoll.filter(this.$_createFilter(queryString)) : polishRoll;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //打磨驳口 筛选
        $_createFilter(queryString) {
            return (polishRoll) => {
                return (polishRoll.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
    }

}
